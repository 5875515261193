<template>
  <section v-if="hasBlogs" class="right-sidebar-blog-stream section">
    <h4 class="title is-4">Articles, Videos, and more</h4>
    <div v-for="(blog, i) in topBlogs" :key="i" class="blog">
      <i class="fa fa-fw" :class="iconClass(blog)"></i>
      <a :href="blog.url">{{ blog.title }}</a>
    </div>
    <a :href="hashtag" class="is-underlined">See all</a>
  </section>
</template>

<script>
export default {
  name: "RightSidebarBlogStream",

  props: {
    tag: { type: String, required: true },
    take: { type: Number, default: 3 },
  },

  data() {
    return {
      blogs: [],
    };
  },

  computed: {
    hasBlogs() {
      return this.blogs.length > 0;
    },

    hashtag() {
      return `/take-5-daily?t=${encodeURIComponent(this.tag)}`;
    },

    topBlogs() {
      return this.blogs.slice(0, this.take);
    },
  },

  methods: {
    iconClass(blog) {
      if (blog.type === "podcast") {
        return "fa-podcast";
      } else if (blog.type === "article") {
        return "fa-newspaper";
      } else if (blog.type == "video") {
        return "fa-video";
      }
      return "";
    },
  },

  mounted() {
    axios
      .get(`${this.hashtag}&p=1&s=${this.take}&format=json`)
      .then((response) => {
        this.blogs = response.data.articles.data;
      })
      .catch((error) => {
        this.blogs = [];
      });
  },
};
</script>
