export default {
    data() {
        return {
            favorites: {
                product: [],
                affiliate: []
            }
        }
    },

    methods: {
        isFavorite(productId) {
            return this.favorites.product.indexOf(productId) >= 0;
        },

        favorite(productId) {
            if (this.isFavorite(productId)) {
                // Unfavorite
                axios.post("/account/unfavorite/product", {"itemId": productId})
                    .then(response => {
                        let ix = this.favorites.product.indexOf(productId);
                        if (ix >= 0) {
                            this.favorites.product.splice(ix, 1);
                        }
                    })
            } else {
                axios.post("/account/favorite/product", {"itemId": productId})
                    .then(response => {
                        this.favorites.product.push(productId);
                    })
            }
        },

        isFavoriteAffiliate(id) {
            return this.favorites.affiliate.indexOf(id) >= 0;
        },

        favoriteAffiliate(id) {
            if (this.isFavoriteAffiliate(id)) {
                // Unfavorite
                axios.post("/account/unfavorite/affiliate", {"itemId": id})
                    .then(response => {
                        let ix = this.favorites.affiliate.indexOf(id);
                        if (ix >= 0) {
                            this.favorites.affiliate.splice(ix, 1);
                        }
                    })
            } else {
                axios.post("/account/favorite/affiliate", {"itemId": id})
                    .then(response => {
                        this.favorites.affiliate.push(id);
                    })
            }
        }
    },

    created() {
        if (this.isLoggedIn) {
            axios.get("/account/favorites")
                .then(response => this.favorites.product = response.data);
            axios.get("/account/favorites/affiliate")
                .then(response => this.favorites.affiliate = response.data);
        }
    }
}

