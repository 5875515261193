<!-- FORK OF https://raw.githubusercontent.com/jgrandar/vue-bulma-paginate -->

<template>
  <nav class="pagination is-centered" v-if="baseUrl" aria-label="Paging Navigation">
    <a href="#" :disabled="activePage < 2 || loading" class="pagination-previous" aria-label="Previous Page" @click="previous">
      <i class="fal fa-arrow-circle-left"></i>
    </a>
    <a href="#" :disabled="isLastPage || loading" class="pagination-next"  aria-label="Next Page" @click="next">
      <i class="fal fa-arrow-circle-right"></i>
    </a>
    <ul class="pagination-list">
      <li v-if="showFirstPageButton">
        <a :href="urlForPage(1)" class="pagination-link" aria-label="Page 1" v-text="1" @click.prevent="goPage(1)" :disabled="loading"/>
      </li>
      <li v-if="buttons[0] > 2">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li v-for="button in buttons" :key="button">
        <a :href="urlForPage(button)" :aria-label="`Page ${button}`" :aria-current="button === activePage ? 'page' : null" :class="{ 'is-current': button === activePage }" class="pagination-link" v-text="button" @click.prevent="goPage(button)" :disabled="loading"/>
      </li>
      <li v-if="buttons[buttons.length - 1] < pagesTotal - 1">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li v-if="showLastPageButton">
        <a :href="urlForPage(pagesTotal)" :aria-label="`Page ${pagesTotal}`" class="pagination-link" v-text="pagesTotal" @click.prevent="goPage(pagesTotal)" :disabled="loading"/>
      </li>
    </ul>
  </nav>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "AjaxPagination",

  props: {
    itemsTotal: {
      type: Number,
      default: 0,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: 1,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
      required: true,
    },
    buttonsMax: {
      type: Number,
      default: 5,
      validator: value => value > 4 && Math.abs(value % 2) === 1,
    },
    baseUrl: {
      required: true,
    },
    queryParameter: {
      type: String,
      default: 'page',
    },
    updateHistory: {
      type: Boolean,
      default: true
    },
    useHref: {
      type: Boolean,
      default: true
    },
    queryParameterSize: {
      type: String,
      default: 'size',
    },
    immediate: {
      type: Boolean,
      default: false
    },
    zeroBased: {
      type: Boolean,
      default: false
    },
    searchParam: {
      type: String,
      default: "q"
    },
    searchTerms: {
      default: null
    },
    sortParam: {
      type: String,
      default: "sort"
    },
    sortDirectionParam: {
      type: String,
      default: "asc"
    },
    sort: {
      default: () => []
    },
    sortDirection: {
      type: String,
      default: "asc"
    },
    additionalParams: {
      type: Object
    },
    appendAdditionalParams: {
      type: Boolean,
      default: false
    },
    hardReloadOnSearch: {
      type: Boolean,
      default: false
    },
    arrowsClickPrevent: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    activePage() {
      return this.currentPage + (this.zeroBased ? 1 : 0);
    },

    pagesTotal() {
      return Math.ceil(this.itemsTotal / this.itemsPerPage);
    },

    buttonsTotal() {
      return this.pagesTotal > this.buttonsMax - 2 ? this.buttonsMax - 2 : this.pagesTotal;
    },

    showFirstPageButton() {
      return this.buttons[0] > 1;
    },

    showLastPageButton() {
      return this.buttons[this.buttons.length - 1] < this.pagesTotal;
    },

    buttons() {
      let firstButton = this.activePage - Math.floor(this.buttonsTotal / 2);
      let lastButton = firstButton + (this.buttonsTotal - Math.ceil(this.buttonsTotal % 2));

      if (firstButton < 1) {
        firstButton = 1;
        lastButton = firstButton + (this.buttonsTotal - 1);
      }
      if (lastButton > this.pagesTotal) {
        lastButton = this.pagesTotal;
        firstButton = lastButton - (this.buttonsTotal - 1);
      }

      const pages = [];
      for (let page = firstButton; page <= lastButton; page += 1) {
        pages.push(page);
      }

      return pages;
    },

    isLastPage() {
      return !this.pagesTotal || (this.activePage > this.pagesTotal - 1);
    },
  },

  watch: {
    sort: debounce(function (sort) {
      this.refresh();
    }, 300),
    sortDirection: debounce(function (sort) {
      this.refresh();
    }, 300),
    searchTerms: debounce(function (sort) {
      if (this.hardReloadOnSearch) {
        this.goPage(this.zeroBased ? 1 : 0);
      } else {
        this.refresh();
      }
    }, 300),
    additionalParams: {
      deep: true,
      handler: debounce(function (sort) {
        if (this.hardReloadOnSearch) {
          this.goPage(this.zeroBased ? 1 : 0);
        } else {
          this.refresh();
        }
      }, 300)
    }
  },

  methods: {
    goPage(page) {
      this.loading = true;
      this.$emit("start-loading", true);
      let pageUrl = this.urlForPage(page);
      axios.get(pageUrl)
          .then(response => {
            this.$emit("page-changed", response.data);

            if (this.updateHistory) {
              const url = new URL(pageUrl)
              url.searchParams.delete("format");
              history.pushState(null, "", url.toString());
            }
          })
          .catch(error => {
            this.$emit("error-loading", error);
          })
          .finally(_ => {
            this.$emit("stop-loading", false);
            this.loading = false;
          });
    },
    refresh() {
      this.goPage(this.activePage);
    },
    previous(event) {
      if(this.arrowsClickPrevent){event.preventDefault();}
      if (this.activePage > 1) {
        this.goPage(this.activePage - 1);
      }
    },
    next(event) {
      if(this.arrowsClickPrevent){event.preventDefault();}
      if (this.activePage < this.pagesTotal) {
        this.goPage(this.activePage + 1);
      }
    },
    urlForPage(page) {
      page = Number(page) - (this.zeroBased ? 1 : 0);
      const url = new URL(this.baseUrl, `${location.protocol}//${location.host}`);
      url.searchParams.set("format", "json");
      url.searchParams.set(this.queryParameter, page);
      if (this.itemsPerPage) url.searchParams.set(this.queryParameterSize, this.itemsPerPage);
      if (this.searchTerms) url.searchParams.set(this.searchParam, this.searchTerms);
      if (this.sort && this.sort.length > 0) url.searchParams.set(this.sortParam, Array.isArray(this.sort) ? this.sort.join() : this.sort);
      if (this.sortDirection) url.searchParams.set(this.sortDirectionParam, this.sortDirection);
      if (this.additionalParams) {
        Object.keys(this.additionalParams).forEach(k => {
          let val = this.additionalParams[k];
          if (Array.isArray(val)) {
            if (this.appendAdditionalParams) {
              val.forEach(v => url.searchParams.append(k, v));
            }
            else {
              url.searchParams.set(k, val.join);
            }
          } else {
            url.searchParams.set(k, val);
          }
        });
      }
      return this.useHref ? url.toString() : "";
    },
  },

  mounted() {
    if (this.immediate) {
      this.goPage(this.currentPage);
    }
  },
};
</script>