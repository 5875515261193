<template>
  <div class="right-sidebar-layout">
    <div id="right-sidebar-layout--header">
      <header class="section">
        <div class="container">
          <nav class="breadcrumb" aria-label="Navigation breadcrumb">
            <ul>
              <li v-for="(crumb, i) in breadcrumbs" :key="i">
                <a v-if="crumb.uri" :href="crumb.uri">{{ crumb.name }}</a>
                <span v-else>{{ crumb.name }}</span>
              </li>
            </ul>
          </nav>
          <h1 class="title is-1">{{ pageTitle }}</h1>
        </div>
      </header>
    </div>

    <section class="section navigation" v-if="navigation.length > 0">
      <div class="container">
        <ul>
          <li v-for="next in navigation" :class="{'active':next.active}">
            <a :href="next.url">{{ next.name }}</a>
          </li>
        </ul>
      </div>
    </section>

    <div class="two-column-layout">
      <div class="container">
        <div class="columns">
          <div id="right-sidebar-layout--left" class="column is-left">
            <slot name="left"></slot>
          </div>
          <div
              id="right-sidebar-layout--right"
              class="column is-right is-one-quarter">
            <slot name="right"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "right-sidebar-layout",

  props: {
    breadcrumbs: {
      type: Array,
      default() {
        return [{uri: "/", name: "Home"}];
      },
    },
    navigation: {
      type: Array,
      default() {
        return [];
      }
    },
    pageTitle: {type: String, required: true},
  },
};
</script>
