<template>
    <div role="img" :aria-label="starDivLabel" class="review-stars" :class="{'is-editable' : editable}" @mouseleave="revertPreview">
        <span v-for="ix in 5">
            <span class="fa" :role="starRole":aria-label="starLabel(ix)" :class="{'fa-star filled' : value >= ix, 'fa-star empty' : value < ix}" @mouseover="previewRating(ix)" @click="chooseRating(ix)">
                <span role="img" :aria-label="starLabel(ix)" class="fa fa-star-half filled" v-if="value < ix && value > (ix-1)"></span>
            </span>
        </span>
      <span>({{`${value ? value : 0}/5`}})</span>
    </div>
</template>

<script>
    export default {
        name: 'ReviewStars',

        props: {
            value: {default: 0},
            editable: {default: false}
        },

        data() {
            return {
                initialValue: this.value
            }
        },

        computed:{
          starDivLabel(){
            if(this.editable){
              return  "review stars"
            }
            else{
              return this.value % 1 > 0 ? `${Math.floor(this.value)}.5 stars` : `${Math.floor(this.value)} stars`;
            }
          },

          starRole(){
            return this.editable? 'button' : 'img';
          }

        },
        methods : {
            chooseRating(rating) {
                if(this.editable) {
                    this.initialValue = rating;
                    this.$emit('input', rating);
                }
            },
            starLabel(val){
              let starsLabel = '';
              if(this.editable){
                starsLabel = `Select ${val} stars out of 5`;
              }
              else {
                if (this.value >= val) {
                  starsLabel = 'filled star';
                } else {
                  starsLabel = this.value % 1 > 0 ? 'half filled star' : 'unfilled star';
                }
              }
              return starsLabel;
            },
            previewRating(rating) {
                if(this.editable) {
                    this.$emit('input', rating);
                }
            },

            revertPreview() {
                if(this.editable) {
                    this.$emit('input', this.initialValue);
                }
            }
        }
    }
</script>
