<template>
  <html-fragment :html="content" v-if="content"></html-fragment>
</template>

<script>

export default {
  name: "ContentBlock",

  props: {
    contentPath: { type: String, required: true },
    contentKey: { type: String, required: true },
  },

  data() {
    return {
      content: null
    }
  },

  mounted() {
    if (this.contentPath && this.contentKey) {
      axios.get(`/content/blocks/${this.contentPath}/${this.contentKey}`)
          .then((response) => {
            this.content = response.data.content;
          });
    }
  }
}
</script>