import { ToastProgrammatic as Toast } from "buefy";

export default class Http {
	
	static get(requestUrl, paramData, successCallback, errorCallback) {
		return Http.send(requestUrl, "get", null, paramData, null, successCallback, errorCallback);
	}
	
	static patch(requestUrl, requestData) {
		return Http.send(requestUrl, "patch", requestData);
	}
	
	static post(requestUrl, requestData) {
		return Http.send(requestUrl, "post", requestData);
	}
	
	static post(requestUrl, requestData, configData, successCallback, errorCallback) {
		return Http.send(requestUrl, "post", requestData, null, configData, successCallback, errorCallback);
	}

	static put(requestUrl, requestData) {
		return Http.send(requestUrl, "put", requestData);
	}
	
	static delete(requestUrl) {
		return Http.send(requestUrl, "delete");
	}
	
	static send(requestUrl, requestMethod, requestData, paramData, configData, successCallback, errorCallback) {
		return axios({
			method: requestMethod,
			url: requestUrl,
			data: (requestData ? requestData : undefined),
			params: (paramData ? paramData : undefined),
			config: (configData ? configData : undefined)
		}).then(response => {
			return new Promise((resolve, reject) => {
				let data = response.data;
				if (typeof successCallback === "function") {
					successCallback(data, response);
				}
				resolve(data);
			});
	    }).catch(err => {
			return new Promise((resolve, reject) => {
				let hasErrorCallback = (typeof errorCallback === "function");
				let errs = Http.parseErrors(err.response, !hasErrorCallback);
				if (hasErrorCallback) {
					errorCallback(errs, error);
				}
				reject({
		    	    errors: errs,
		    	    error: !!err ? err : new Error()
				});
			});
	    });
	}
	
	static parseErrors(response, showErrorToast) {
		let errors = {};
		
		if (response.data && response.data.errorUuid) {
			errors = Http.parseErrorAttributes(response);
		} else if (response.data && response.data.errorCode) { // Yes, a bit wonky
			errors = Http.parseApiErrorObject(response);
		}
		
		if (showErrorToast && response.status > 499) {
	    	Toast.open({
                message: errors.global ? errors.global : "We had a problem handling your request",
                position: 'is-bottom',
                type: 'is-danger'
			});
		}
		
		return errors;
	}
	
	static parseErrorAttributes(response) {
		let result = {};
		if (response.data.errors) {
			// Handle any spring exceptions
			response.data.errors.forEach(error => {
				let errorKey = null;
				if (error.objectName && error.field) {
					errorKey = error.objectName + "." + error.field;
				} else if (error.field) {
					errorKey = error.field;
				} else {
					
				}
				if (!errorKey) {
					errorKey = "global";
				}
				const message = error.defaultMessage;
				result[errorKey] = message;
			});
		} else {
			result.global = response.data.message ? response.data.message : "We had a problem handling your request";
		}
		return result;
	}
	
	static parseApiErrorObject(response) {
		let result = {};
		let fieldErrors = response.data.errors ? response.data.errors.fieldErrors : [];
		let globalErrors = response.data.errors ? response.data.errors.globalErrors : [];
		
		fieldErrors.forEach(error => {
			let errorKey = null;
			if (error.objectName && error.field) {
				errorKey = error.objectName + "." + error.field;
			} else if (error.field) {
				errorKey = error.field;
			}
			const message = error.message;
			result[errorKey] = message;
		});
		
		// Get any global errors ... this is generally just one, so that's the assumption here.
		globalErrors.forEach(error => {
			result.global = error.message;
		});
		
		if (fieldErrors.length === 0 && globalErrors.length === 0) {
			result.global = "We had a problem handling your request";
		}
		
		return result;
	}
}