<template>
	<div class="aura-wrapper" v-cloak>
		<div class="aura-static" :style="{backgroundImage: `url(${$cdn(staticFailoverImage)})`}"></div>
		<div class="aura-container">
			<div class="aura-shape" :style="{background: `linear-gradient(45deg, ${color1}, ${color2})`}"></div>
		</div>
		<div class="aura-content">
			<slot></slot>
		</div>
	</div>
</template>

<script>
    export default {
        name: "AuraHeader",
        props: {
            color1: String,
            color2: String,
            staticFailoverImage: String
        }
    }
</script>