export default {
  filters: {
    date(value) {
      // Add colon to timezone offset for Safari and IE
      const safeDate = value.replace(/([+\-]\d\d)(\d\d)$/, "$1:$2");
      const date = Date.parse(safeDate);
      return new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(date);
    },
  },
};
