export default {
	state: {
	    country: null,
	    user: null,
	    customer: null,
	    rewardsProfile: null,
	    professionalProfile: null,
	    audioPlayer: null,
	    lastSyncedSubscriptions: null,
	  },

	  getters: {
	    country(state, getters) {
	      return state.country;
	    },

	    user(state, getters) {
	      return state.user;
	    },

	    cartQuantity(state, getters) {
	      return window.sessionStorage.getItem("cartQuantity");
	    },

	    customer(state, getters) {
	      return state.customer;
	    },

	    rewardsProfile(state, getters) {
	      return state.rewardsProfile;
	    },

	    professionalProfile(state, getters) {
	      return state.professionalProfile;
	    },

	    audioPlayer(state, getters) {
	      return state.audioPlayer;
	    },

	    lastSyncedSubscriptions(state, getters){
	      return window.sessionStorage.getItem("lastSyncedSubscriptions");
	    },


	  },

	  mutations: {
	    setCustomer(state, customer) {
	      state.customer = customer;
	    },

	    setCountry(state, country) {
	      state.country = country;
	    },

	    setUser(state, user) {
	      state.user = user;
	    },

	    setCartQuantity(state, cartQuantity) {
	      window.sessionStorage.setItem("cartQuantity", cartQuantity);
	    },

	    setRewardsProfile(state, profile) {
	      state.rewardsProfile = profile;
	    },

	    setProfessionalProfile(state, profile) {
	      state.professionalProfile = profile;
	    },

	    setAudioPlayer(state, audioPlayer) {
	      if (state.audioPlayer) {
	        state.audioPlayer.pause();
	      }
	      state.audioPlayer = audioPlayer;
	    },

	    setLastSyncedSubscriptions(state, lastSyncedSubscriptions){
	      window.sessionStorage.setItem("lastSyncedSubscriptions", lastSyncedSubscriptions);
	    },

	  },
}