<template>
  <a :href="href" class="image-tile-wrapper"
     :aria-label="ariaLinkDescription"
     :tabindex="ariaHide ? -1 : null"
     :role="ariaHide ? 'none' : null"
     :aria-hidden="ariaHide ? true : null"
     :target="target"
     v-cloak>
    <div class="image-tile" :class="{ 'is-small': smallTile, 'is-tall': tallTile, 'invert-text-color' : invertTextColor}">
      <div class="background-image" v-if="!bigIcon" :style="{ backgroundImage: backgroundUrl }" :aria-label="altText"></div>
      <div class="background-image" v-else :style="{ backgroundColor: iconColor }" :aria-label="altText"></div>

      <div class="overlay" :class="{ 'overlay-bg': overlayBackground, 'gradient' : gradientBackground}" :style="{ 'background' : overlayBackgroundStyle }">
        <div class="overlay-content">
          <span class="tag is-black" v-if="badgeName" :style="{backgroundColor: badgeColor}">{{ badgeName }}</span>
        </div>
        <span class="love " v-if="loveMe">
					<i class="fa fa-heart"/>
				</span>
        <span class="article" v-if="articleIcon">
					<i class="fa fa-newspaper" style="color: #7fe0a7;"/>
				</span>
      </div>
      <div class="tile-titles" :class="{'invert-text-color' : invertTextColor}" v-if="!smallTile">
        <div><i :class="iconName" :style="{color: iconColor}"/></div>
        <h3 class="title is-1 is-capitalized">{{ title }}</h3>
        <p class="is-size-5">{{ subtitle }}</p>
      </div>
      <div class="tile-icon" v-if="smallTile && iconName && !bigIcon">
        <div><i :class="iconName" :style="{color: iconColor}"/></div>
      </div>
      <div class="tile-big-icon" v-if="bigIcon">
        <div class="icon-wrapper">
          <div class="icon-fg"><i :class="iconName"/></div>
          <div class="icon-bg"><i :class="iconBgName"/></div>
        </div>
      </div>
    </div>
    <div class="titles" v-if="smallTile">
      <h3 class="is-size-5 is-capitalized">{{ title }}</h3>
      <p class="is-size-6" v-if="subtitle">{{ subtitle }}</p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ImageCard',
  props: {
    target:{default: ""},
    overlayBackground: {default: false},
    overlayColor1: {default: 'rgba(0, 0, 0, 0.37)'},
    overlayColor2: {default: 'rgba(0, 0, 0, 0)'},
    gradientBackground: {default: false},
    loveMe: {default: false},
    articleIcon: {default: false},
    smallTile: {default: false},
    tallTile: {default: false},
    title: String,
    subtitle: String,
    iconName: String,
    iconBgName: String,
    iconColor: String,
    badgeName: String,
    badgeColor: String,
    href: String,
    background: String,
    height: Number,
    bigIcon: {default: false},
    invertTextColor: {default: false},
    ariaHide: {type: Boolean, default: false},
    altText: String,
  },
  computed: {
    ariaLinkDescription() {
      const description = []

      if (this.badgeName) description.push(this.badgeName);
      if (this.title) description.push(this.title);
      if (this.subtitle) description.push(this.subtitle);

      return description.join(" ");
    },
    backgroundUrl() {
      return this.background ? `url(${this.$cdn(this.background)})` : "";
    },
    overlayBackgroundStyle() {
      return this.gradientBackground ? `linear-gradient(0, ${this.overlayColor1}, 25%, ${this.overlayColor2}, 50%, ${this.overlayColor2})` : null;
    }
  }
}
</script>