<template>
  <div class="marketing-headline">
    <div class="columns" :class="{ 'is-vcentered': center }">
      <div class="left column">
        <div class="marketing-headline--title" :class="titleClass">
          <slot name="title"></slot>
        </div>
      </div>

      <div v-if="!full" class="right column">
        <div v-if="hasSubTitle" class="marketing-headline--subtitle" :class="subtitleClass">
          <slot name="subtitle"></slot>
        </div>
        <div v-if="hasBlurb" class="marketing-headline--blurb" :class="blerbClass">
          <slot name="blurb"></slot>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketingHeadline",

  props: {
    full: { type: Boolean, default: false },
    center: { type: Boolean, default: false },
    titleClass: { type: String },
    subtitleClass: { type: String },
    blerbClass: { type: String },
  },

  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },

    hasSubTitle() {
      return !!this.$slots.subtitle;
    },

    hasBlurb() {
      return !!this.$slots.blurb;
    },
  },
};
</script>