<template>
	<div class="subscribe-form" v-click-outside="close">
		<div class="sign-up" v-if="!hasMessage">
			<div class="field is-grouped button-embedded">
				<div class="control">
					<input class="input" type="text" placeholder="Email address" v-model="emailAddress" @keydown.enter.prevent="subscribe">
				</div>
				<div class="control">
					<button class="button is-primary" @click.prevent="subscribe" :disabled="!hasEmail" aria-label="Submit email"><i class="fal fa-paper-plane"></i></button>
				</div>
			</div>
		</div>
		<div class="subscribe-message" v-if="hasMessage">{{message}}</div>
    </div>
</template>

<script>
	import VueJsonp from "vue-jsonp";
	import vClickOutside from "v-click-outside";
	
	Vue.use(VueJsonp);
	Vue.use(vClickOutside);
	
	export default {
        name: "NewsletterSubscription",
        
        data() {
            return {
            	emailAddress : "",
            	open: false,
                saving : false,
                message: null
            };
        },

        methods: {
        	subscribe() {
        		if(this.hasEmail) {
	        		this.$jsonp("https://thorne.us14.list-manage.com/subscribe/post-json?u=9c1702cb48ba0fba685a6a669&id=7b3cc73f41", {
	        			callbackQuery: "c",
	        			callbackName: "jsonp_cb",
	        			EMAIL: this.emailAddress
	        		}).then(json => {
	        	        this.message = json.msg;
					}).catch(err => {
						this.message = "Error submitting request.";
					});
        		}
        	},
        	
        	close() {
        		this.open = false;
        		this.message = null;
        	}
        },
        
        computed: {
        	hasMessage() {
        		return this.message != null && this.message.length > 0;
        	},
        	
        	hasEmail() {
        		return this.emailAddress.length > 0 && this.emailAddress.includes("@");
        	}
        }
    };
</script>

<style lang="scss" scoped>
.subscribe-form {
	position: relative;
	
	.subscribe {
		img {
			margin-right: 10px;
		}
	}
}
</style>