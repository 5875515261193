<template>
  <div class="marketing-card is-relative">
    <i v-if="!imageCard" class="icon" :class="iconClass" :style="iconStyle"></i>
    <div v-if="imageCard" class="image" :style="imageStyle">
      <img :role="!imageDescription ? 'presentation' : null" :alt="imageDescription" :src="$cdn(iconImg)" />
    </div>
    <div class="marketing-card--main container">
      <div class="header subtitle is-3">
        <slot name="header"></slot>
      </div>
      <div class="blurb">
        <div class="title is-2" :style="titleHeightStyle">
          <slot name="title"></slot>
        </div>
        <div class="description">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="marketing-card--footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketingCard",

  props: {
    iconClass: { type: String, default: "" },
    iconImg: { type: String, default: null },
    imageWidth: { type: String, default: "50%" },
    iconColor: { type: String, default: "#ff9e1c" },
    iconPos: {
      type: Array,
      default() {
        return ["65%", "-4%"];
      },
    },
    titleHeight: { type: String, default: null },
    imageDescription: { type: String, default: null },
  },

  computed: {
    iconStyle() {
      return {
        color: this.iconColor,
        left: this.iconPos[0],
        top: this.iconPos[1],
      };
    },

    imageStyle() {
      return {
        width: this.imageWidth,
      };
    },

    titleHeightStyle() {
      return !!this.titleHeight ? { height: this.titleHeight } : {};
    },

    imageCard() {
      return !!this.iconImg;
    },
  },
};
</script>