module.exports={
  "retail": {
    "features": [
      {
        "code": "birthday",
        "label": "Birthday discount",
        "valueType": "string"
      },
      { "code": "shop", "label": "Rewards Shop", "valueType": "check" },
      {
        "code": "promos",
        "label": "Exclusive promotions",
        "valueType": "check"
      },
      {
        "code": "gift",
        "label": "Free health test<br/><span class='tier-sublabel'>*Excludes Essential and Advanced Health Panels</span>",
        "subLabel": true,
        "valueType": "check"
      }
    ],
    "tiers": [
      {
        "tier": "insider",
        "label": "Insider",
        "amount": null,
        "icon": "crown",
        "iconColor": "is-blue",
        "next": "gold",
        "features": {
          "birthday": "10%",
          "shop": false,
          "promos": false,
          "gift": false
        }
      },
      {
        "tier": "gold",
        "label": "Gold",
        "amount": 350,
        "icon": "crown",
        "iconColor": "is-gold",
        "next": "black",
        "features": {
          "birthday": "15%",
          "shop": true,
          "promos": true,
          "gift": false
        }
      },
      {
        "tier": "black",
        "label": "Black",
        "amount": 1000,
        "icon": "crown",
        "iconColor": "is-black",
        "next": null,
        "features": {
          "birthday": "20%",
          "shop": true,
          "promos": true,
          "gift": true
        }
      }
    ]
  },
  "professional": {
    "features": [
      {
        "code": "anniversary",
        "label": "Anniversary discount",
        "valueType": "string"
      },
      { "code": "shop", "label": "Rewards Shop", "valueType": "check" },
      {
        "code": "promos",
        "label": "Exclusive promotions",
        "valueType": "check"
      },
      {
        "code": "education",
        "label": "Exclusive webinars and education",
        "valueType": "check"
      },
      {
        "code": "priority",
        "label": "Priority access to customer service",
        "valueType": "check"
      },
      {
        "code": "tour",
        "label": "Private tour of Thorne's new <br/>South Carolina manufacturing facility",
        "valueType": "check"
      }
    ],
    "tiers": [
      {
        "tier": "insider",
        "label": "Insider",
        "amount": null,
        "icon": "crown",
        "iconColor": "is-blue",
        "next": "gold",
        "features": {
          "anniversary": "10%",
          "shop": false,
          "promos": false,
          "education": false,
          "priority": false,
          "tour": false
        }
      },
      {
        "tier": "gold",
        "label": "Gold",
        "amount": 4000,
        "icon": "crown",
        "iconColor": "is-gold",
        "next": "black",
        "features": {
          "anniversary": "15%",
          "shop": true,
          "promos": true,
          "education": true,
          "priority": false,
          "tour": false
        }
      },
      {
        "tier": "black",
        "label": "Black",
        "amount": 20000,
        "icon": "crown",
        "iconColor": "is-black",
        "next": "vip",
        "features": {
          "anniversary": "20%",
          "shop": true,
          "promos": true,
          "education": true,
          "priority": true,
          "tour": false
        }
      },
      {
        "tier": "vip",
        "label": "VIP",
        "amount": 50000,
        "icon": "glass-cheers",
        "iconColor": "is-fuchsia",
        "next": null,
        "features": {
          "anniversary": "20%",
          "shop": true,
          "promos": true,
          "education": true,
          "priority": true,
          "tour": true
        }
      }
    ]
  }
}
