<template>
	<div class="rewards-table">
		<div class="rewards-tier is-features">
			<div class="tier-icon">&nbsp;</div>
			<div class="tier-header">Tiers</div>
			<div class="tier-items">
				<div class="tier-item" v-for="feature in program.features" v-html="feature.label"></div>
			</div>
		</div>
		<template v-for="tier in program.tiers">
			<div class="rewards-tier" :class="{'is-active': isActiveTier(tier.tier)}">
				<span v-if="isActiveTier(tier.tier)" class="check">
					<b-icon pack="fas" icon="check-circle" size="is-medium"></b-icon>
				</span>
				<div class="tier-icon">
					<b-icon pack="fas" :icon="tier.icon" size="is-medium" :custom-class="tier.iconColor"></b-icon>
				</div>
				<div class="tier-header">{{ tier.label }}<span v-if="tier.amount"> {{ tier.amount | toCurrency }}+</span></div>
				<div class="tier-items">
					<template v-for="feature in program.features">
						<div class="tier-item" :data-feature="stripHtmlTags(feature.label)">
							<span v-if="feature.valueType === 'string'">{{ getFeatureValue(feature.code, tier) }}</span>
							<b-icon v-else-if="getFeatureValue(feature.code, tier)" pack="fas" icon="check" size="is-small"></b-icon>
							<b-icon v-else pack="fas" icon="horizontal-rule" size="is-small" custom-class="is-grey"></b-icon>
              <br v-if="feature.subLabel"/>
              <span v-if="feature.subLabel" class="tier-sublabel">&nbsp</span>
						</div>
					</template>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import programs from "../data/reward-programs.json";

    export default {
        name: "RewardTiersTable",
        
        props: {
            activeTier: {
    			type: String,
            	default : null,
    			validator: (val) => [null, "insider", "gold", "black", "vip"].includes(val)
            },
    		programType: {
    			type: String,
    			default: "retail",
    			validator: (val) => ["retail", "professional"].includes(val)
    		},
        },
        
        computed: {
        	program() {
        		return this.programs[this.programType];
        	},
        	
        	programs() {
        		return programs;
        	},
        },
        
        methods: {
        	isActiveTier(code) {
       			return this.activeTier === code;
        	},
        	
        	getFeatureValue(code, tier) {
        		return tier.features[code];
        	},
        	
        	stripHtmlTags(value) {
        		return value.replace(/<[^>]*>?/gm, '');
        	},
        },
    }
</script>