<template>
  <div class="product-tile" :class="{ragnarok: isRagnarok}">
    <div class="product-container">
      <a @click="actionLogic" :href="href" :aria-label="ariaLinkDescription" @focus="showDescription()" @blur="hideDescription()">
        <div class="product flex-middle-center" :class="{'is-disabled' : disabled}" @mouseover="showDescription()" @mouseleave="hideDescription()">
          <img :src="product.imageUrl + '?background=' + backgroundColor" :class="{ overlaid: viewingDescription }" :alt="product.name"/>

          <div class="quick-info" v-if="showFavoriteStatus">
            <div class="favorites">
              <div class="favorite flex-middle-center" v-if="isLoggedIn" @click.prevent="favorite($event)">
                <i class="is-rounded fas fa-heart" :class="{ filled : isFavorite }"></i>
              </div>
            </div>
          </div>
          <slide-y-up-transition>
            <div class="quick-info" v-show="viewingDescription">
              <div class="favorites">
                <div class="favorite flex-middle-center" v-if="isLoggedIn" @click.prevent="favorite($event)">
                  <i class="is-rounded fas fa-heart" :class="{ filled : isFavorite }"></i>
                </div>
              </div>
              <div class="headline">{{ product.headline }}</div>
              <p v-html="product.shortDescription"></p>
            </div>
          </slide-y-up-transition>
          <div class="price" v-if="showPrice">
            <span class="tag is-light">{{ formattedPrice }}</span>
          </div>
          <div class="product-flags" v-if="!viewingDescription">
            <div v-if="product.locked" class="tag">
              <b-icon pack="fal" size="is-small" icon="lock"></b-icon>
            </div>
            <!-- Custom badges (created via admin) override all other badges; not the same as the other "customBadge" prop -->
            <template v-if="product.customBadges != null && product.customBadges.length > 0">
              <div v-for="badge in product.customBadges"
                   class="custom-badge"
                   :style="{ color: badge.hexColor }">

                {{ badge.value }}
              </div>
            </template>
            <div v-else-if="customBadge" class="custom-badge">{{ customBadge }}</div>
            <div v-else-if="product.badges && product.badges.indexOf('New') >= 0" class="new">New</div>
            <div v-else-if="product.badges && product.badges.indexOf('Exclusive') >= 0" class="exclusive">Exclusive</div>
            <div v-else-if="product.discontinued" class="discontinued">To Be Discontinued</div>
            <div v-else-if="!product.discontinued && product.extendedBackorder" class="out-of-stock">Out of Stock</div>
            <div v-else-if="!product.discontinued && !product.extendedBackorder && product.outOfStock" class="out-of-stock">Pre-Order</div>
          </div>
          <div class="us-soccer" v-if="product.soccer">
            <img alt="US Soccer Logo" :src="$cdn('cdn://images/components/us-soccer.svg')"/>
          </div>
        </div>
      </a>
      <div class="cart-button-container" v-if="shouldShowAddToCart">
        <add-to-cart-button
            v-if="!product.hasVariations"
            :product-id="product.id"
            :product-name="product.name"
            :out-of-stock="false"
            :extended-backorder="false"
            :show-button-text="false">
          <i class="fa fa-cart-plus"></i>
        </add-to-cart-button>
        <a v-else
                @click="actionLogic" :href="href"
                :aria-label="`View product page for ${product.name}`"
                role="button"
                class="button is-primary"
                tabindex="0">
          <i class="fa fa-cart-plus"></i>
        </a>
      </div>
    </div>
    <div class="description">
      <a @click="actionLogic" :href="href" tabindex="-1" aria-hidden="true" role="none">
        <div class="name" v-if="showName">{{ product.name }}</div>
        <div class="benefits">{{ formattedBenefits }}</div>
      </a>

      <div class="explicit" v-if="explicitDescription" @click.stop="" >
        <slot></slot>
      </div>
      <div v-else>
        <div class="rating" v-if="showRatings">
          <ReviewStars :value="product.averageRating"></ReviewStars>
        </div>
        <div class="rating" v-if="showPersonalRatings">
          <ReviewStars :value="product.rating"></ReviewStars>
        </div>
        <div class="purchased" v-if="showPurchaseDate" aria-hidden="true" role="none">
          Purchased: {{ mostRecentPurchase }}
        </div>
        <div class="purchases" v-if="showPurchaseCount" aria-hidden="true" role="none">
          Purchased: x{{ product.quantityPurchased }}
        </div>
        <div class="more-options" v-if="actionText">
          <span aria-hidden="true" class="bullet">&bull;</span> <a @click="actionLogic" :href="href" :aria-label="`${product.name}: ${actionText}`">{{ actionText }}</a>
        </div>
        <div class="more-options" v-else-if="product.hasVariations">
          <span aria-hidden="true" class="bullet">&bull;</span> <a @click="actionLogic" :aria-label="`More options for ${product.name}`" :href="href">More Options</a>
        </div>
        <div class="more-options" v-else-if="product.locked">
          <span aria-hidden="true" class="bullet">&bull;</span> <a @click="actionLogic"  :aria-label="`Explore ${product.name}`" :href="href">Explore</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SlideYUpTransition} from "vue2-transitions";
import ReviewStars from "./reviews/ReviewStars.vue";
import AddToCartButton from "./AddToCartButton.vue";

export default {
  name: "ProductTile",

  components: {
    AddToCartButton,
    SlideYUpTransition,
    ReviewStars
  },

  props: {
    product: {default: null, type: Object},
    useLoyaltyPoints: {default: false},
    disabled: {default: false},
    isFavorite: {default: false},
    showPrice: {default: true},
    isRagnarok: {default: false},
    actionText: {default: null, type: String},
    action: {default: null, type: Function},
    explicitDescription: {default: false},
    showRatings: {default: true},
    showPersonalRatings: {default: false},
    showPurchaseDate: {default: false},
    showPurchaseCount: {default: false},
    showFavoriteStatus: {default: false},
    showName: {default: true},
    showAddToCart: {default: false},
    customBadge: {default: null, type: String},
    backgroundColor: {default: "#f7f9fb", type: String}
  },

  data: function () {
    return {
      viewingDescription: false
    }
  },

  computed: {
    shouldShowAddToCart() {
      return this.showAddToCart && !this.product.outOfStock && !this.product.extendedBackorder;
    },

    ariaLinkDescription() {
      const description = []

      if (this.actionText) description.push(this.actionText);
      description.push(this.product.name);
      description.push(this.product.headline);
      if (this.showPrice) description.push(this.formattedPrice);
      if (this.customBadge) description.push(this.customBadge);

      if (this.product.badges && this.product.badges.indexOf('New') >= 0) description.push("New");
      if (this.product.badges && this.product.badges.indexOf('Exclusive') >= 0) description.push("Exclusive");
      if (this.product.discontinued) description.push("To Be Discontinued");
      if (this.product.averageRating) description.push(`${this.product.averageRating} Stars`);
      if (!this.product.discontinued && this.product.extendedBackorder) description.push("Out of Stock");
      if (!this.product.discontinued && !this.product.extendedBackorder && this.product.outOfStock) description.push("Pre-Order");

      return description.join(" ");
    },

    formattedBenefits() {
      var benefits = null;

      if (this.product.benefits && this.product.benefits.length > 0) {
        if (this.product.benefits.length > 2) {
          benefits = this.product.benefits.slice(0, 2).join(", ") + " + " + (this.product.benefits.length - 2) + " more";
        } else {
          benefits = this.product.benefits.join(", ");
        }
      }

      return benefits;
    },

    formattedPrice() {
      var formattedPrice = "";

      if (!this.useLoyaltyPoints) {
        if (this.product.hasMultiplePrices) {
          if (this.product.priceRange !== null && this.product.priceRange.lowPrice !== null && this.product.priceRange.highPrice !== null) {
            formattedPrice = `${this.product.priceRange.lowPrice.replace(".00", "")}-${this.product.priceRange.highPrice.replace(".00", "")}`;
          }
        }
        else if (this.product.price !== null) {
          formattedPrice = this.product.price.replace(".00", "");
        }
      } else if (this.product.loyaltyPointCost !== null) {
        formattedPrice = this.product.loyaltyPointCost + " points";
      }

      return formattedPrice;
    },

    mostRecentPurchase() {
      const date = new Date(this.product.lastOrdered.substring(0, 10));
      return new Intl.DateTimeFormat('en', {month: 'numeric', day: 'numeric', year: 'numeric'}).format(date);
    },

    href() {
      return !this.action ? this.product.url : null;
    }
  },

  methods: {
    showDescription() {
      if (!this.isRagnarok) {
        this.viewingDescription = true;
      }
    },

    actionLogic() {
      if (this.action) {
        this.action();
      }
    },

    hideDescription() {
      if (!this.isRagnarok) {
        this.viewingDescription = false;
      }
    },

    favorite(event) {
      this.$emit("favorite", this.product.id);
      event.stopPropagation();
    }
  }

}
</script>
