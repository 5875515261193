<template>
  <b-modal
      v-model="showModal"
      :can-cancel="false"
      animation=""
      scroll="keep"
      aria-role="dialog"
      aria-labelledby="added-to-cart-title"
      aria-describedby="cart-product-details"
      aria-modal
      auto-focus
      trap-focus
      has-modal-card>

    <div class="cart-modal" v-if="showModal">
      <b-button class="close-button"
                @click="close"
                aria-label="Close added to cart dialog"
                tabindex="0"
                icon-pack="fas"
                icon-left="times">
      </b-button>

      <p class="cart-header" id="added-to-cart-title" :aria-label="`Added ${cartProduct.name} to cart`">
        <b-icon icon="check-circle" pack="fas" aria-hidden="true"></b-icon>
        Added to Cart
      </p>
      <div class="cart-content">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            <img class="product-image" :src="cartProduct.imageUrl + '?width=96&height=96'" alt="" />
          </div>
          <div class="column">
            <p class="header" id="cart-product-name">{{ cartProduct.name }}</p>
            <div class="cart-details" id="cart-product-details">
              <b-tag :aria-label="`Subtotal ${cartProduct.subtotal}`">{{ cartProduct.subtotal }}</b-tag>
              <p :aria-label="`Quantity of ${cartProduct.quantity}`">Qty {{ cartProduct.quantity }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-actions">
        <a href="/cart" class="button" tabindex="0">View Cart ({{ cartQuantity }})</a>
        <a href="/cart?checkout=1" class="button is-black" tabindex="0">Checkout</a>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "CartModal",

  props: {
    cartQuantity: {type: Number},
    cartProduct: {
      name: {type: String},
      subtotal: {type: String},
      imageUrl: {type: String},
      quantity: {type: Number}
    }
  },

  data() {
    return {
      showModal: false
    }
  },

  methods: {
    open() {
      this.showModal = true;
    },

    close() {
      this.$emit('closed');
      this.showModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./scss/layouts/bulma-theme.scss";

$rem-baseline: 14px;

.cart-modal {
  color: #555555;
  background-color: #ffffff;
  padding: rem(29px);
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  right: rem(29px);
  top: rem(61px);
  border-radius: 6px;
  max-width: 50%;

  @include tablet {
    max-width: 75%;
    top: 10rem;
  }

  @include mobile {
    border-radius: 0;
    max-width: 100%;
    width: 100%;
    top: 10rem;
    left: 0;
    margin: 0;
  }

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: rem(18px);
    background: none;
    border: none;
  }

  .cart-header {
    font-size: rem(16px);
    font-weight: bold;
    padding-top: rem(12px);
    padding-bottom: rem(12px);

    .icon {
      margin-right: rem(17px);
      color: #7fe0a7;
      font-size: rem(18px);
    }
  }

  .cart-content {
    padding-top: rem(18px);
    padding-bottom: rem(18px);

    .header {
      font-size: rem(16px);
      font-weight: bold;
      border-bottom: solid 1px #ebebeb;
      padding-bottom: rem(10px);
      margin-bottom: rem(19px);
    }

    .tag {
      margin-right: rem(12px);
      padding: rem(7px);
      font-size: rem(12px);
    }

    .product-image {
      padding: rem(16px);
      border-radius: rem(6px);
      background-color: #edf1f5;
      width: rem(130px);
      height: rem(130px);
    }

    .cart-details {
      display: flex;
    }
  }

  .cart-actions {
    a {
      &:not(:last-child) {
        margin-right: rem(10px);
      }

      .button {
        height: rem(50px);
        @include desktop {
          width: rem(178px);
        }
      }
    }
  }
}
</style>
