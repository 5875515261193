<template>
	<div ref="modal" class="hero-modal">
		<div class="modal is-flex">
			<div class="modal-background" @click="close()"></div>
			<div class="modal-content" :class="modalClass">
				<button type="button" class="modal-close is-large" @click="close()"/>
				<div class="upper" v-if="icon" :style="{backgroundColor: backgroundColor}">
					<div class="stacked">
						<i class="fas" :class="icon"/>
						<i class="fal" :class="icon"/>
					</div>
				</div>
				<div class="lower flex-column-middle-center">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'HeroModal',
        props: {
            backgroundColor: String,
            icon: String,
            modalClass: String,
        },

        methods: {
            close() {
                this.$emit('close');
            },

            keyPress(event) {
                // Esc key
                if (this.isActive && event.keyCode === 27) {
                    this.close();
                }
            },
        },

        created() {
            if (typeof window !== 'undefined') {
                document.addEventListener('keyup', this.keyPress)
            }
        }
    }
</script>
