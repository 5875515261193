<template>
  <div v-if="hasPosts" class="blog-carousel">
    <div class="level is-mobile">
      <div class="level-left">
        <h1 class="title level-item">{{ title }}</h1>
      </div>
      <div class="level-right">
        <a :href="take5Uri"> See all <i class="fa fa-chevron-right"></i> </a>
      </div>
    </div>

    <carousel
      navigation-next-label="<i class='fas fa-arrow-right'></i>"
      navigation-prev-label="<i class='fas fa-arrow-left'></i>"
      :navigation-enabled="true"
      :pagination-enabled="false"
      :scroll-per-page="true"
      :per-page-custom="[
        [0, 1],
        [612, 2],
        [1024, 3],
        [1216, 4],
      ]"
    >
      <slide class="post" v-for="post in posts" :key="post.urlKey">
        <image-card-new
          :background="post.mediaUrl"
          :href="post.url"
          :height="152"
          :icon="icon(post)"
          :icon-color="iconColor(post)"
          :icon-size="14"
          :icon-badge="true"
          :hidden-title="post.title"
          :padding="14"
        >
        </image-card-new>
        <h1 class="subtitle">{{ post.title }}</h1>
        <p class="desc">{{ post.author.name }}</p>
      </slide>
    </carousel>
  </div>
</template>

<script>
import ImageCardNew from "../features/ImageCardNew.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    "image-card-new": ImageCardNew,
    carousel: Carousel,
    slide: Slide,
  },

  props: {
    hashtag: { type: String, required: true },
    title: { type: String, default: "Read more on this topic:" },
  },

  data() {
    return {
      posts: [],
    };
  },

  computed: {
    encodedTag() {
      return encodeURIComponent(this.hashtag);
    },

    hasPosts() {
      return this.posts.length > 0;
    },

    take5Uri() {
      return `/take-5-daily?t=${this.encodedTag}`;
    },
  },

  methods: {
    icon(blogPost) {
      if (blogPost.type === "article") {
        return "fa fa-newspaper";
      } else if (blogPost.type === "video") {
        return "fas fa-video";
      } else if (blogPost.type === "podcast") {
        return "fa fa-podcast";
      } else {
        return "fa fa-newspaper";
      }
    },

    iconColor(blogPost) {
      if (blogPost.type === "article") {
        return "#7fe0a7";
      } else if (blogPost.type === "video") {
        return "#5bc2e7";
      } else if (blogPost.type === "podcast") {
        return "#e45dbf";
      } else {
        return "#ffffff";
      }
    },

    streamBlogs(url) {
      axios
        .get(url)
        .then((response) => {
          this.posts = this.posts.concat(response.data.data);
          this.streamBlogs(response.data.nextPageUrl);
        })
        .catch((error) => {});
    },
  },

  created() {
    this.streamBlogs(`/take-5-daily/hashtag?t=${this.encodedTag}&p=1&s=10`);
  },
};
</script>
