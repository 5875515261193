<template>
  <div class="vertical-blog-stream">
    <span ref="blogStart" />
    <div class="blog" v-for="blog in blogs" :key="blog.id">
      <hr />
      <div class="columns is-vcentered">
        <div class="left column is-one-quarter">
          <image-card-new
            :background="blog.mediaUrl"
            :href="blog.url"
            :height="181"
            :icon="iconClass(blog.type)"
            :iconColor="iconColor(blog.type)"
            :iconSize="14"
            :iconBadge="true"
            :aria-hide="true"
            badgeColor="rgba(25, 25, 25, 0.8)"
          ></image-card-new>
        </div>
        <div class="right column">
          <h2 class="title is-2">
            <a :href="blog.url"> {{ blog.title }} </a>
          </h2>
          <p class="caption">{{ blog.caption }}</p>
          <hr />
          <span class="author">
            {{ blog.publishAt | date }} &bull; {{ blog.author.name }}
          </span>
        </div>
      </div>
    </div>
    <div v-show="paging" class="pagination-holder">
      <ajax-pagination
        :items-total="totalBlogs"
        :items-per-page="perPage"
        :current-page="currentPage"
        :base-url="baseUrl"
        :update-history="false"
        :immediate="true"
        queryParameter="p"
        queryParameterSize="s"
        @page-changed="pageChanged"
      />
    </div>
  </div>
</template>

<script>
import ImageCardNew from "../features/ImageCardNew.vue";
import AjaxPagination from "../AjaxPagination.vue";
import DateFilter from "../../mixins/date-filter.js";
import BlogHelpers from "../../mixins/blog-helpers.js";
import VueScrollTo from "vue-scrollto";

export default {
  name: "VerticalBlogStream",

  mixins: [DateFilter, BlogHelpers],

  components: {
    "image-card-new": ImageCardNew,
    "ajax-pagination": AjaxPagination,
  },

  props: {
    tags: {
      type: Array,
      default() {
        return [];
      },
    },
    perPage: { type: Number, default: 3 },
    noPage: { type: Boolean, default: false },
  },

  data() {
    return {
      blogs: [],
      totalBlogs: 0,
      currentPage: 1,
      initialPageLoad: true,
    };
  },

  computed: {
    baseUrl() {
      return ["take-5-daily", this.tagsQuery]
        .filter((it) => it !== null && it !== "")
        .join("?");
    },

    tagsQuery() {
      return this.tags
        .map((it) => window.encodeURIComponent(it))
        .map((it) => `t=${it}`)
        .join("&");
    },

    paging() {
      return !this.noPage;
    },
  },

  watch: {
    totalBlogs(newVal) {
      this.$emit("total", newVal);
    },
  },

  methods: {
    pageChanged(data) {
      this.totalBlogs = data.articles.totalResults;
      this.blogs = data.articles.data;
      this.currentPage = data.paging.p;

      if (!this.initialPageLoad) {
        VueScrollTo.scrollTo(this.$refs.blogStart);
      } else {
        this.initialPageLoad = false;
      }
    },
  },
};
</script>