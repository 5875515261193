<template>
  <div class="marketing-product-set">
    <product-set
      :skus="skus"
      :is-ragnarok="true"
      :actionText="actionText"
      slide-classes="product-padding"
      :show-add-to-cart="this.showAddToCart"
      :carousel-options="{
        perPage: 1,
        perPageCustom: this.customPerPage,
      }"
    ></product-set>
  </div>
</template>

<script>
import ProductSet from "../ProductSet.vue";

export default {
  name: "MarketingProductSet",

  components: {
    "product-set": ProductSet,
  },

  props: {
    skus: {type: Array, default: null},
    actionText: {type: String, default: "Learn More"},
    showAddToCart: {default: false},
    customPerPage: {
      type: Array,
      default() {
        return [
          [769, 2],
          [1024, 3],
        ];
      }
    }
  }
}
</script>