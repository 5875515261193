<template>
  <div
    class="marketing-banner"
    :class="{ 'is-mobile': isMobileLayout }"
    :style="{ height: height }"
  >
    <div
      class="background"
      :class="{ 'is-mobile': isMobileLayout }"
      :style="backgroundStyle"
    >
      <img :alt="imageDescription" :src="$cdn(image)" :class="{ 'is-mobile': isMobileLayout }" />
    </div>
    <div class="container">
      <div class="columns is-vcentered">
        <div class="left column">
          <div class="title is-spaced">
            <slot name="title"></slot>
          </div>
          <div class="subtitle">
            <slot name="description"></slot>
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketingBanner",

  props: {
    image: { type: String },
    fixed: { type: Boolean, default: false },
    height: { type: String, default: "100vh" },
    mobileCutoff: { default: "desktop" },
    imageDescription:  { type: String },
  },

  data() {
    return {
      width: window.innerWidth,
    };
  },

  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url("${this.$cdn(this.image)}")`,
        backgroundAttachment: this.fixed ? "fixed" : undefined,
        backgroundSize: `auto ${this.height}`,
      };
    },

    cutoffPixels() {
      return this.cutoffToPixels(this.mobileCutoff);
    },

    isMobileLayout() {
      return !(this.width >= this.cutoffPixels);
    },
  },

  methods: {
    handleResize(event) {
      this.width = window.innerWidth;
    },

    cutoffToPixels(cutoff) {
      const cutoffs = {
        mobile: 0,
        tablet: 769,
        desktop: 1024,
        widescreen: 1216,
        fullhd: 1408,
      };

      if (typeof cutoff === "number") {
        return cutoff;
      } else if (cutoffs[cutoff]) {
        return cutoffs[cutoff];
      } else {
        return cutoffs.desktop;
      }
    },
  },

  created() {
    window.addEventListener("resize", this.handleResize);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>