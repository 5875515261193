<template>
  <div :class="size" class="global-image-tile" :style="{'background': this.backgroundStyle, 'color': this.color}">
    <span class="image" v-if="image"><img role="presentation" alt="" v-if="!imageFillTile" :style="{'padding': this.imagePadding}" :src="image"/></span>
    <span class="icon" v-else-if="icon && iconPack"><b-icon size="is-small" :pack="iconPack" :icon="icon"></b-icon></span>
    <span class="text" v-else>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "ImageTile",
  props: {
    size: {type: String, default: "is-small"},
    text: {type: String, default: "T"},
    imagePadding: {type: String, default: "0"},
    icon: {type: String},
    iconPack: {type: String},
    image: {type: String},
    imageFillTile: {default: false},
    background: {type: String},
    backgroundColor1: {type: String},
    backgroundColor2: {type: String},
    color: {type: String, default: "#ffffff"}
  },
  computed: {
    backgroundStyle() {
      if (this.image && this.imageFillTile) {
        return `url('${this.image}')`;
      } else if (this.image && !this.background) {
        return "#edf1f5";
      } else if (this.backgroundColor1 && this.backgroundColor2) {
        return `linear-gradient(-45deg, ${this.backgroundColor1}, ${this.backgroundColor2})`
      } else if (this.background) {
        return this.background;
      } else {
        return "#000000";
      }
    }
  }
}
</script>