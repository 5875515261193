<template>
  <hero-modal modal-class="login-modal" @close="close()">
    <div class="columns is-multiline">
      <div class="column is-full" v-if="notification">
        <notification icon="fas fa-exclamation" :text="notification"></notification>
      </div>
      <div class="column is-full">
        <h1 class="title is-1" tabindex="0" ref="title">{{ title }}</h1>
      </div>
      <div class="column is-full">
        <hr/>
      </div>

      <div class="column is-full">
        <form @submit.prevent="login">
          <div class="columns is-multiline">
            <div class="column is-full" v-if="errored">
              <div class="notification-banner is-danger" tabindex="0" role="alert">
                <span class="icon"><i class="fas fa-exclamation"></i></span>
                <span>{{ errorMessage }}</span>
              </div>
            </div>
            <div class="column is-full has-text-left">
              <fieldify
                  label="Email"
                  label-id="email"
                  :errors="errors"
                  error-field="loginForm.email"
                  v-floating-label>
                <input
                    inputmode="email"
                    v-model.lazy="$v.loginForm.email.$model"
                    class="input is-large"
                    placeholder="Email"
                    aria-labelledby="email"
                    aria-required="true"
                    autofocus="autofocus"
                    v-autofocus
                />
              </fieldify>
            </div>
            <div class="column is-full has-text-left">
              <fieldify
                  label="Password"
                  label-id="password"
                  :errors="errors"
                  error-field="loginForm.password"
                  v-floating-label>
                <input
                    type="password"
                    name="password"
                    aria-labelledby="password"
                    aria-required="true"
                    v-model.lazy="$v.loginForm.password.$model"
                    class="input is-large"
                    placeholder="Password"/>
                <a class="forgot-password is-underlined"
                   href="/forgot-password"
                   aria-label="Reset your password">Forgot?</a>
              </fieldify>
            </div>
            <div class="column is-full">
              <div class="captcha">
                <div id="recaptcha"></div>
                <fieldify :errors="errors" error-field="recaptchaResponse">
                  <div class="help is-danger is-float-left" v-if="!validCaptcha">You must complete the captcha to signup</div>
                </fieldify>
              </div>
            </div>
            <div class="column is-full sign-in">
              <b-button type="is-dark" native-type="submit" :loading="loading" expanded>Sign In</b-button>
            </div>
          </div>
        </form>
      </div>

      <div class="column is-full sign-up">
        No Account? <a class="is-underlined" :href="signupUrl" aria-label="Create a new account">Start Here</a>
      </div>
    </div>
  </hero-modal>
</template>

<script>
import validationMixin from "../mixins/validation-support";

const {required, email} = window.validators;
import AuthenticationService from "../classes/global/AuthenticationService.js";
import Notification from "./global/Notification.vue";
import HeroModal from "./features/HeroModal.vue";
import Fieldify from "./Fieldify.vue";
import {Button} from "buefy";

Vue.use(Button);

export default {
  name: "LoginModal",

  mixins: [validationMixin],

  components: {
    notification: Notification,
    "hero-modal": HeroModal,
    fieldify: Fieldify,
  },

  props: {
    title: {type: String, default: "Sign In"},
    notification: {type: String},
    reload: {type: Boolean, default: true},
    redirectUri: {type: String}
  },

  data() {
    return {
      loading: false,
      errored: false,
      errors: {},
      errorMessage: null,
      loginForm: {
        email: null,
        password: null,
      },
      updateForm: {
        birthday: null,
      },
      recaptchaRequired: requireLoginCaptcha,
      recaptchaSiteKey: recaptchaSiteKey,
      captcha: null,
      failedAttempts: 0,
      validCaptcha: true
    };
  },

  computed: {
    enabledCaptcha() {
      return !!this.recaptchaSiteKey && (!!this.recaptchaRequired || this.failedAttempts > 0);
    },

    signupUrl() {
      return `/signup/customer?redirect=${this.redirectUri || window.location.pathname}`;
    }
  },

  mounted() {
    this.$refs.title.focus();
    this.loadCaptcha();
  },

  methods: {
    loadCaptcha() {
      if (this.enabledCaptcha && !this.captcha) {
        // This checking is here largely to handle the use case of the Cart page, which loads this
        // modal on component mount, which usually happens before recaptcha gets loaded
        if(typeof(grecaptcha) === 'undefined' || typeof(grecaptcha.render) === 'undefined') {
          setTimeout(this.loadCaptcha, 250)
        } else {
          this.captcha = grecaptcha.render('recaptcha', {'sitekey': this.recaptchaSiteKey});
        }
      }
    },

    getCaptchaResponse() {
      this.validCaptcha = true;
      let captchaResponse = null;
      if (this.enabledCaptcha) {
        try {
          captchaResponse = grecaptcha.getResponse(this.captcha);
          this.validCaptcha = captchaResponse.length > 0;
        } catch (e) {
          this.validCaptcha = !this.enabledCaptcha;
        }
      }
      return captchaResponse;
    },

    async resetCaptcha() {
      if (this.enabledCaptcha && this.captcha) {
        grecaptcha.reset(this.captcha);
      }
    },

    reset() {
      this.loginForm.email = null;
      this.loginForm.password = null;
      this.errors = {};
      this.loading = false;
      this.errored = false;
      this.failedAttempts = 0;
      this.resetCaptcha();
    },

    close() {
      this.reset();
      this.$emit("close");
    },

    finalize() {
      if (this.redirectUri) {
        window.location.replace(this.redirectUri);
      } else if (this.reload) {
        window.location.reload();
      } else {
        this.reset();
      }
    },

    login() {
      this.$v.$reset();
      this.$v.$touch();

      const captchaResponse = this.getCaptchaResponse();
      if (this.$v.$invalid || !this.validCaptcha) {
        return;
      }

      this.loading = true;
      AuthenticationService.login(this.loginForm.email, this.loginForm.password, captchaResponse)
          .then((response) => {
            this.loading = false;
            this.$emit("login");
            this.finalize();
          })
          .catch((error) => {
            this.loading = false;
            this.errored = true;
            this.failedAttempts++;
            if (error.data && error.data.message) {
              this.errorMessage = error.data.message;
            } else {
              this.errorMessage = "Invalid email or password";
            }
            this.$emit("error");
          });
    },
  },

  watch: {
    enabledCaptcha(newVal, oldVal) {
      if (newVal && (newVal !== oldVal)) {
        this.loadCaptcha();
      }
    }
  },

  validations: {
    loginForm: {
      email: {
        required,
        email,
      },

      password: {
        required,
      },
    },
  },
};
</script>
