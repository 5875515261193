<template>

    <add-to-cart-button 
    	:id="id"
    	:product-id="productData.id" 
    	:product-name="productData.name" 
    	:out-of-stock="productData.outOfStock"
    	:extended-backorder="productData.extendedBackorder"
    	:quantity="quantity"
    	:frequency="frequency"
    	:customer-email="customerEmail"
    	:is-large="isLarge"
    	:button-text="buttonText">
    </add-to-cart-button>

</template>

<script>
    import AddToCartButton from "./AddToCartButton.vue";
    
    export default {
        name: 'AjaxAddToCartButton',

        components: {
            'add-to-cart-button': AddToCartButton,
        },

        props: {
        	id: {default: null},
            sku: {required: true},
            quantity: {default: 1},
            frequency: {default: 0},
            customerEmail: {default: ''},
            isLarge: {default: false},
            buttonText: {default: "Add to Cart"}
        },

        data() {
            return {
                productData: {
                    id: 928,
                    name: 'TSETING',
                    outOfStock: false,
                    extendedBackorder: false
                }
            };
        },

        mounted() {
			axios.get("/products/inventory/" + this.$props.sku)
				.then(response => {
					this.productData.id = response.data.productId;
					this.productData.name = response.data.productName;
					this.productData.outOfStock = response.data.outOfStock;
					this.productData.extendedBackorder = response.data.extendedBackorder;
				});
        }
    };
</script>
