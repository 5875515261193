<template>

  <div class="affiliate-content">
    <div class="card-title">{{cardTitle}}</div>
    <div class="card-subtitle">{{cardSubtitle}}</div>
    <div class="video-container">
      <video controls preload="none" :poster="posterUrl">
        <source :src="sourceUrl" type="video/mp4"/>
      </video>
    </div>
    <a :href="affiliateShop" class="button is-black shop-button">Shop Now</a>


</div>
</template>

<script>
export default {
  name: 'AffiliateVideoCard',

  props: {
    source: { type: String },
    poster: { type: String },
    cardTitle: { type: String },
    cardSubtitle: { type: String },
    affiliateShop: {type: String},
  },

  computed:{

    posterUrl() {
      return this.$cdn(this.poster);
    },

    sourceUrl() {
      return this.$cdn(this.source);
    },
  }
};

</script>
<style lang="scss" scoped="scoped">
@import "./scss/layouts/bulma-theme.scss";
.affiliate-content {
  .shop-button{
    width:100%;
    height:3.1rem;
  }
  .card-title{
    color:#000000;
    font-weight: 500;
    font-size: 1.71rem;
  }

  .card-subtitle{
    color:#000000;
    font-size:1.1rem;
    font-weight: 400;
    margin-bottom: 0.9rem;
  }
  background-color: #F0F0F0;
  display:inline-block;
  @include mobile {
    display:block;
    margin-top:2rem;
    width:auto;
    height:auto;
  }
  text-align: left;
  padding: 1.5rem;
  width: 26rem;


  margin-left:1rem;
  margin-right:1rem;
  border-radius: 8px;
  height: 52rem;

  .video-container{
    height:39rem;
    position:relative;
    margin-bottom:0.8rem;

    video{
      min-width: 100%;
      min-height: 100%;
      volume:0.2;

      text-align:center;
      width: auto;
      height:100%;
    }
  }
}
</style>