import Http from "../global/Http.js";

export default class AuthenticationService {
	
	static async login(emailAddress, pass, captchaResponse) {
		let loginForm = { email: emailAddress, password: pass};
		let url = !!captchaResponse ? `/login/a?g-recaptcha-response=${captchaResponse}` : `/login/a`
		return Http.post(url, loginForm);
	}
	
}