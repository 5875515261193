import Http from "../global/Http.js";

export default class CartService {

    constructor() {
        this.products = [];
        this.productLines = [];
    }

    pushProductToDataLayer(product) {
        window.dataLayer.push({
            "event": "addToCart",
            "productSku": product.sku,
            "productName": product.name,
            "productPrice": product.subtotal.replace(/[^\d.-]/g, ""),
            "quantity": product.quantity,
            "currencyCode": "USD"
        });
    }

    addProducts(products) {
        return new Promise((resolve, reject) => {
            const productIds = products.map(product => product.productId);
            const requestData = { items: products };

            axios.patch("/cart/items", requestData)
                .then(response => {
                    this.productLines = response.data.lines;

                    response.data.lines
                        .filter(line => productIds.includes(line.productId))
                        .forEach(line => this.pushProductToDataLayer(line));

                    resolve(response);
                })
                .catch(error => reject(error));
        });
    }

    addProduct(product) {
        return new Promise((resolve, reject) => {
            let responseMessage = null;

            const responseObject = {
                msg: null,
                lines: null,
            };

            if(!this.products.includes(product.id)){
                this.products.push(product.id);

                Http.post("/cart/item/" + product.id, {quantity: product.quantity, frequency: product.frequency, usePoints: product.usePoints})
                    .then(response => {
                        this.productLines = response.lines;

                        // find line that has the newly added product id (one line per product)
                        const productLine = response.lines
                            .findLast(line => product.id === line.productId);

                        this.pushProductToDataLayer(productLine);

                        responseMessage = `${product.name} was added to <a style="color:white;text-decoration:underline;" href="/cart">your cart</a>`;
                        responseObject.msg = responseMessage;
                        responseObject.lines = this.productLines;
                        resolve(responseObject);
                    })
                    .catch(error => {
                      if (error.response && error.response.data && error.response.data.message && error.response.data.message !== "Forbidden") {
                          responseMessage = error.response.data.message
                      } else {
                          responseMessage = `An error occurred adding ${product.name} to your cart`
                      }

                      responseObject.msg = responseMessage;
                      reject(responseObject);
                    })
                    .finally(() => {
                       let index = this.products.indexOf(product.id);
                       this.products.splice(index, 1);
                    });
            }
            else{
                responseMessage = "Product has already been added to cart";
                responseObject.msg = responseMessage;
                responseObject.lines = this.productLines;
                resolve(responseObject);
            }
        });
    }
}
