<template>
  <div
    class="horizontal-marketing-card"
    :style="{ 'min-height': `${minHeight}px` }"
    ref="card"
    v-cloak
  >
    <div
      :style="badgeStyle"
      class="horizontal-marketing-card--icon icon"
      :class="{ 'horizontal-marketing-card--icon-condensed': isCondensed }"
      ref="badge"
    >
      <span v-if="badgeText">{{badgeText}}</span>
      <i v-else :class="iconClass"></i>
    </div>

    <div
      class="horizontal-marketing-card--image"
      :class="{ 'horizontal-marketing-card--image-condensed': isCondensed }"
    >
      <img role="presentation" alt="" :src="image" @load="setComponentDimensions" ref="image" />
    </div>

    <div
      class="horizontal-marketing-card--blurb"
      :class="{
        'horizontal-marketing-card--blurb-condensed': isCondensed,
        'horizontal-marketing-card--centered': centered,
      }"
      :style="blurbShift"
    >
      <h2 v-if="title" class="title">{{ title }}</h2>
      <div v-if="$slots.default" class="blurb">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalMarketingCard",

  props: {
    title: { type: String, default: null },
    imgSrc: { type: String, default: "https://via.placeholder.com/120x150" },
    minHeight: { type: Number, default: 120 },
    iconClass: { type: String, default: null },
    iconColor: { type: String, default: "white" },
    iconSize: { type: String, default: "1rem" },
    badgeColor: { type: String, default: "#e03e52" },
    badgeText: { type: String, default: null },
    noBadge: { type: Boolean, default: false },
    condenseWidth: { type: Number, default: 480 },
    centered: { type: Boolean, default: false },
  },

  data() {
    return {
      componentWidth: 0,
      componentHeight: 0,
      imageWidth: 0,
      badgeWidth: 0,
    };
  },

  computed: {
    badgeStyle() {
      return {
        backgroundColor: this.badge ? this.badgeColor : "transparent",
        color: this.badge ? this.iconColor : "transparent",
        fontSize: this.iconSize,
        left: this.badgeShift,
      };
    },

    badge() {
      return !this.noBadge;
    },

    image() {
      return this.$cdn(this.imgSrc);
    },

    isCondensed() {
      return this.componentWidth < this.condenseWidth;
    },

    badgeShift() {
      return `${this.imageWidth - this.badgeWidth}px`;
    },

    blurbShift() {
      return this.isCondensed
        ? null
        : { "padding-left": `${this.imageWidth + this.badgeWidth}px` };
    },
  },

  methods: {
    setComponentDimensions() {
      this.componentWidth = this.$refs.card.clientWidth;
      this.componentHeight = this.$refs.card.clientHeight;
      this.imageWidth = this.$refs.image.clientWidth;
      this.badgeWidth = this.$refs.badge.clientWidth;
    },
  },

  mounted() {
    window.addEventListener("resize", this.setComponentDimensions);
    this.setComponentDimensions();
  },

  destroyed() {
    window.removeEventListener("resize", this.setComponentDimensions);
  },
};
</script>