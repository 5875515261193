<template>
	<div class="sharing" v-cloak v-click-outside="close" v-bind:class="{ 'inline': displayInline, 'flex-middle-right': !displayInline }">
		<div class="share-button" @click.prevent="open = !open" v-if="!displayInline">
			<i class="fas fa-share"></i>
		</div>
		<social-sharing inline-template v-show="open || displayInline">
			<div class="networks">
				<network network="facebook">
					<i class="fab fa-facebook"></i>
				</network>
				<network network="twitter">
					<i class="fab fa-twitter"></i>
				</network>
				<network network="pinterest">
					<i class="fab fa-pinterest"></i>
				</network>
			</div>
		</social-sharing>
	</div>
</template>

<script>
	import vClickOutside from "v-click-outside";
	import SocialSharing from "vue-social-sharing";
	
	Vue.use(vClickOutside);
	Vue.use(SocialSharing);
	
	export default {
        name: "SocialNetworkSharing",
        
		props: {
			displayInline: false
		},
        
        data() {
            return {
            	open: false
            };
        },

        methods: {
        	close() {
        		this.open = false;
        	}
        }
    };
</script>

<style lang="scss">
	.sharing {
		flex-grow: 1;
		position: relative;
			
		.share-button {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			width: 36px;
			height: 36px;
			border-radius: 2px;
			border: solid 1px #cbcbcb;
			cursor: pointer;
				
			i {
				color: #cbcbcb;
				font-size: 15px;
			}
		}
		
		&.inline {
			position: static;
			
			.networks {
				position: inherit;
				box-shadow: none;
				background-color: inherit;
				padding: 0;
			}
		}
		
		.networks {
			border-radius: 3px;
			background-color: #ffffff;
			box-shadow: 0 2px 29px 0 rgba(0, 0, 0, 0.3);
			padding: 25px;
			position: absolute;
			top: 25px;
			right: 15px;
			z-index: 10;
			display: flex;
		
			i {
				cursor: pointer;
				font-size: 24px;
				
				&.fa-facebook {
					color: #4460a0;
					
					&:hover {
						color: lighten(#4460a0, 20%);
					}
				}
				
				&.fa-twitter {
					color: #00aaec;
					
					&:hover {
						color: lighten(#00aaec, 20%);
					}
				}
				
				&.fa-pinterest {
					color: #cc2127;
					
					&:hover {
						color: lighten(#cc2127, 20%);
					}
				}
			}
			
			:not(:last-child) {
				margin-right: 20px;
			}
		}
	}
</style>