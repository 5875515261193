<template>
  <section class="product-link-nav">
    <div class="container">
      <ul>
        <li class="active">Why Ours</li>
        <li><a aria-label="Buy Now. Go to product page." :href="href">Buy Now</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductLinkNav",
  props: {
    href: { type: String, default: null },
  },
};
</script>