import qs from "qs";
import { getField, updateField } from "vuex-map-fields";

const historyAction = {
	PUSH: "push",
	REPLACE: "replace",
	SKIP: "skip"
};

export default {
	namespaced: true,
	
	state: {
		searchQuery: "",
		hideFilters: false,
		tab: null,
		pagedLists: {},
		currentPage: {},
		breadcrumbs: [],
		resultsAvailableFor: [],
		isDispensary: false,
		isAmbassador: false,
		icons: {
			"Products": "fas fa-capsules",
			"Articles": "fas fa-newspaper",
			"Videos": "fas fa-video",
			"Resources": "fas fa-diagnoses",
			"Ingredients": "fas fa-mortar-pestle",
			"Podcasts": "fas fa-podcast",
			"Affiliates": "fas fa-user",
		},
		sort: null,
		showFilterModal: false,
		expandedFacets: [],
		featuredProducts: {
			title: null,
			content: []
		},
		dispensary: {
			avatarUrl: null,
			headerUrl: null,
			whyThorne: null,
			goodHealth: null
		},
		mostRelevantQuiz: null,
		marketingTest: null,
		backLink: null,
		loading: false,
		ingredientSearchRef: null
	},
	
	actions: {
		bootstrap({ commit, dispatch, state, getters }, pageLoadData) {
			commit("setPagedLists", pageLoadData.pagedLists);
			commit("setSearchQuery", pageLoadData.searchQuery);
			commit("setHideFilters", pageLoadData.hideFilters);
			commit("setIsDispensary", pageLoadData.isDispensary);
			commit("setIsAmbassador", pageLoadData.isAmbassador);
			commit("setMostRelevantQuiz", pageLoadData.mostRelevantQuiz);
			commit("setMarketingTest", pageLoadData.marketingTest);
			commit("setResultsAvailableFor", state.pagedLists.resultsAvailableFor);
			commit("setBackLink", pageLoadData.backLink);
			
			dispatch("initializeTabSelection");
		},
		
		initialize({ commit, dispatch, state, getters }, historyChoice) {
			// Select the page that corresponds to the proper tab
			commit("setCurrentPage", state.pagedLists[state.tab.toLowerCase()]);
			commit("setSort", state.currentPage.combinedSortSelection);
			commit("setBreadcrumbs", [
				{url: "/", label: "Home"},
				{url: state.currentPage.nakedPageUrl, label: state.tab}
			]);
			
			// Set the meta title
			if(!getters.isSearch && (state.pagedLists.metaTitle || state.currentPage.pageTitle)) {
				let titleToUse = state.pagedLists.metaTitle ? state.pagedLists.metaTitle : state.currentPage.pageTitle;
				document.title = titleToUse + " | Thorne";
			}
			
			// Set the featured items (in use with dispensaries)
			if (state.isDispensary) {
				commit("setFirstBreadcrumb", {url: state.currentPage.nakedPageUrl, label: state.pagedLists.dispensarySettings.name});
				commit("setFeaturedProducts", {
					title: state.pagedLists.dispensarySettings.featuredProductsTitle || "My Favorites",
					content: state.pagedLists.dispensarySettings.featuredProducts
				});
				
				commit("setDispensary", {
					avatarUrl: state.pagedLists.dispensarySettings.avatarImageUrl,
					headerUrl: state.pagedLists.dispensarySettings.headerImageUrl,
					bio: state.pagedLists.dispensarySettings.bio,
					whyThorne: state.pagedLists.dispensarySettings.whyThorne,
					goodHealth: state.pagedLists.dispensarySettings.goodHealth
				});
			} else {
				commit("setMostRelevantQuiz", mostRelevantQuiz);
				commit("setMarketingTest", marketingTest);
			}

			// Now do the history tracking
			switch(historyChoice) {
				case historyAction.PUSH:
					history.pushState({tab: state.tab, page: state.currentPage.pageNumber}, "", document.location);
					break;
				case historyAction.REPLACE:
					history.replaceState({tab: state.tab, page: state.currentPage.pageNumber}, "", document.location);
					break;
			}
		},
		
		search({ commit, dispatch, state, getters }) {
			// Close any modals/whatever
			commit("setShowFilterModal", false);
			
			// Build up the AJAX call
			let query = {};
			
			// Add the search query if it's there
			if(state.searchQuery) {
				query.q = state.searchQuery;
			}
			
			// Add filtering criteria to the query
			let filters = getters.selectedFilters;
			if(filters.length) {
				query.f = filters;
			}
			
			// Add the sort information
			if(state.currentPage.sortField) {
				query[state.currentPage.sortFieldName] = state.currentPage.sortField;
				query[state.currentPage.sortDirectionFieldName] = state.currentPage.sortDirection;
			}
			
			// Do the ajax call
			let pageUrl = location.origin + location.pathname + "?" + qs.stringify(query);
			
			commit("setLoading", true);
			
			axios.get(pageUrl + "&format=json")
				.then((response) => {
					commit("setPagedLists", response.data.page);
					commit("setMarketingTest", response.data.mostRelevantTest);
					commit("setMostRelevantQuiz", response.data.mostRelevantQuiz);
					
					// We need to pushState ourselves because we need the URL to change
					history.pushState(null, "", pageUrl);
					dispatch("initialize", historyAction.REPLACE);
					
					// Make sure we scroll up now that filters are on the left again
					VueScrollTo.scrollTo("main");
				})
				.finally(() => {
					commit("setLoading", false);
				});
		},
		
		changePage({ commit, dispatch, state, getters }, pageData) {
			commit("setPagedLists", pageData.page);
			commit("setMarketingTest", pageData.mostRelevantTest);
			commit("setMostRelevantQuiz", pageData.mostRelevantQuiz);
			
			dispatch("initialize", historyAction.REPLACE);
			VueScrollTo.scrollTo("main");
		},
		
		showResultsFor({ commit, dispatch, state, getters }, { tab, skipHistory }) {
			commit("setTab", tab);
			dispatch("initialize", skipHistory ? historyAction.SKIP : historyAction.PUSH);
			
			// Record an event for marketing
			window.dataLayer.push({"event": "GAEvent", "eventCategory": "Search Tab", "eventAction": "Switch", "eventLabel": state.tab});
		},
		
		sortChanged({ commit, dispatch, state, getters }) {
			if(state.sort && state.sort.length) {
				// Sort updated as single string, need to parse
				let sortInfo = state.sort.split("_");
				commit("setCurrentPageSort", { sortField: sortInfo[0], sortDirection: sortInfo[1] });
			} else {
				// Clear out the search
				commit("setCurrentPageSort", { sortField: null, sortDirection: null });
			}
			
			dispatch("search");
		},
		
		clearFilters({ commit, dispatch, state, getters }, skipSearch) {
			commit("clearCurrentPageFacets");
			
			if(state.ingredientSearchRef) {
				state.ingredientSearchRef[0].clear();
			}
			
			if(skipSearch !== true) {
				dispatch("search");
			}
		},
		
		expandFacet({ commit, dispatch, state, getters }, facet) {
			commit("addExpandedFacet", facet.name);
		},
		
		initializeTabSelection({ commit, dispatch, state, getters }) {
			if(state.resultsAvailableFor.length) {
				// Check for a hash and select based on that
				if(window.location.hash) {
					const resultsIndex = state.resultsAvailableFor.findIndex(r => r.toLowerCase() === window.location.hash.substring(1));
					if(resultsIndex >= 0) {
						commit("setTab", state.resultsAvailableFor[resultsIndex]);
					}
				} else {
					commit("setTab", state.resultsAvailableFor[0]);
				}
				
				dispatch("initialize", historyAction.REPLACE);
			}
		},
		
		toggleFilter({ commit, dispatch, state, getters }, { facetName, facetValue }) {
			let facet = state.currentPage.facets.find(facet => facet.name === facetName);
			
    		if(facet) {
    			commit("setLoading", true);
    			const valueIndex = facet.selected.findIndex(value => value === facetValue);
    			if(valueIndex === -1) {
    				// Per Marketing, this should act like a brand new page, so clear existing filters
    				dispatch("clearFilters", true);
        			facet.selected.push(facetValue);
        			dispatch("search");
    			} else {
    				// It's already selected, so just remove it and do the search
    				facet.selected.splice(valueIndex, 1);
    				dispatch("search");
    			}
    		}
    	}
	},
	
	mutations: {
		updateField,
		
		setPagedLists(state, pagedLists) {
			state.pagedLists = pagedLists;
		},
		
		setCurrentPage(state, currentPage) {
			state.currentPage = currentPage;
		},
		
		setBreadcrumbs(state, breadcrumbs) {
			state.breadcrumbs = breadcrumbs;
		},
		
		setFirstBreadcrumb(state, firstBreadcrumb) {
			state.breadcrumbs[1] = firstBreadcrumb;
		},
		
		setSearchQuery(state, query) {
			state.searchQuery = query;
		},
		
		setHideFilters(state, hideFilters) {
			state.hideFilters = hideFilters;
		},
		
		setIsDispensary(state, isDispensary) {
			state.isDispensary = isDispensary;
		},
		
		setIsAmbassador(state, isAmbassador) {
			state.isAmbassador = isAmbassador;
		},

		setSort(state, sort) {
			state.sort = sort;
		},
		
		setCurrentPageSort(state, {sortField, sortDirection}) {
			state.currentPage.sortField = sortField;
			state.currentPage.sortDirection = sortDirection;
		},
		
		setFeaturedProducts(state, featuredProducts) {
			state.featuredProducts = featuredProducts;
		},
		
		setDispensary(state, dispensary) {
			state.dispensary = dispensary;
		},
		
		setMostRelevantQuiz(state, mostRelevantQuiz) {
			state.mostRelevantQuiz = mostRelevantQuiz;
		},
		
		setMarketingTest(state, marketingTest) {
			state.marketingTest = marketingTest;
		},
		
		setShowFilterModal(state, showFilterModal) {
			state.showFilterModal = showFilterModal;
		},
		
		setLoading(state, loading) {
			state.loading = loading;
		},
		
		setTab(state, tab) {
			state.tab = tab;
		},
		
		setResultsAvailableFor(state, resultsAvailableFor) {
			state.resultsAvailableFor = resultsAvailableFor;
		},
		
		setBackLink(state, backLink) {
			state.backLink = backLink;
		},
		
		clearCurrentPageFacets(state) {
			state.currentPage.facets.forEach(facet => {
				facet.selected = [];
			});
		},
		
		addExpandedFacet(state, facetName) {
			state.expandedFacets.push(facetName);
		},
		
		setIngredientSearchRef(state, ingredientSearchRef) {
			state.ingredientSearchRef = ingredientSearchRef;
		}
	},
	
	getters: {
		getField,
		showFilterModal(state, getters){
			return state.showFilterModal;
		},
		
		bannerText(state, getters) {
			var text = null;
			
			if(!getters.isSearch) {
				if(state.currentPage.pageTitle) {
					text = state.currentPage.pageTitle;
				} else if(state.tab) {
					text = state.tab;
				}
			} else {
				text = "Search results for '" + state.searchQuery + "'";
			}
			
			return text;
		},
		
		isSearch(state, getters) {
			return state.searchQuery && state.searchQuery.length > 0;
		},
		
		isHomogeneousResults(state, getters) {
			return state.resultsAvailableFor.length === 1;
		},
		
		productSortOptions(state, getters) {
			let options = [
				{label: "Sort by Price (low to high)", value: "price_asc"},
				{label: "Sort by Price (high to low)", value: "price_desc"},
				{label: "Sort by Rating (low to high)", value: "averageRating_asc"},
				{label: "Sort by Rating (high to low)", value: "averageRating_desc"},
				{label: "Sort by Name", value: "name.keyword_asc"}
			]
			
			// Search gets one more
			if(state.searchQuery) {
				options.unshift({label: "Relevance", value: ""});
			}
			
			return options;
		},
		
		selectedFilters(state, getters) {
			let filters = [];
			
			if(state.currentPage && state.currentPage.facets) {
				state.currentPage.facets.forEach(facet => {
					if(facet.selected && facet.selected.length > 0) {
						facet.selected.forEach(selectedValue => {
							filters.push(facet.name + "_" + selectedValue);
						})
					}
				});
			}
			
			return filters;
		},
		
		isFiltered(state, getters) {
			return getters.selectedFilters.length > 0;
		},

		selectedSortLabel(state, getters) {
			return getters.productSortOptions.find(opt => opt.value.split("_")[0] === state.currentPage.sortField).label;
		},
		
		pageElementRange(state, getters) {
			const start = (state.currentPage.pageNumber-1) * state.currentPage.pageSize + 1;
			const end = (start-1) + state.currentPage.data.length;
			return { start: start, end: end }
		},
		
		isEmpty(state, getters) {
			return !state.currentPage || !state.currentPage.hasOwnProperty("data") || (state.currentPage.data && state.currentPage.data.length == 0);
		},
		
		filterString(state, getters) {
			let allFilterValues = [];
			state.currentPage.facets.forEach(facet => {
				allFilterValues = allFilterValues.concat(facet.selected);
			});
			return allFilterValues.join(" &bull; ");
		},
		
		hasFeaturedProducts(state, getters) {
			return state.featuredProducts.content && state.featuredProducts.content.length > 0;
		},
		
		shopAllTitle(state, getters) {
			let value = ""
			if (state.isDispensary) {
				value = state.pagedLists.dispensarySettings.ambassador ? "Storefront" : "Dispensary";
			}
			return `Shop All ${value}`;
		},

		tile3(state, getters) {
			const genericQuiz = {
				iconClass: "fal fa-clipboard-list-check",
				title: "Supplement Quiz",
				desc: "Take one of our quizzes to figure out the best supplement for you.",
				link: "Explore Quizzes",
				href: "/quizzes",
				background: "/images/pages/search/marketing-product-tiles/general-quiz.png",
			}

			if (!state.isDispensary && !state.loading) {
				if (state.currentPage.pageNumber === 2) {
					return {
						iconClass: "fal fa-user-md",
						title: "Get recommendations from local practitioners",
						desc: "Receive answers and realistic recommendations",
						link: "Connect with a Thorne professional",
						href: "/find-a-health-professional",
						background: "/images/pages/search/marketing-product-tiles/local-practitioner.png",
					}
				} else if (!getters.isSearch && !getters.isFiltered && state.currentPage.pageNumber == 1) {
					return genericQuiz;
				} else if (state.mostRelevantQuiz) {
					return {
						iconClass: "fal fa-clipboard-list-check",
						title: state.mostRelevantQuiz.name,
						desc: state.mostRelevantQuiz.description,
						link: "Take the quiz",
						href: `/quizzes/${state.mostRelevantQuiz.urlKey}`,
						background: "/images/pages/search/marketing-product-tiles/specific-quiz.png",
					}
				} else {
					return genericQuiz;
				}
			}
			return null;
		},

		tile7(state, getters) {
			const genericTest = {
				iconClass: "fal fa-vial",
				title: "Looking to achieve your health goals?",
				desc: "Take one of our health tests and get the answers you need.",
				link: "Explore health tests",
				href: "/tests",
				background: "/images/pages/search/marketing-product-tiles/general-test.png",
			}

			if (!state.isDispensary && !state.loading) {
				if (state.currentPage.pageNumber === 2) {
					return {
						iconClass: "fal fa-badge-percent",
						title: "Save up to 10% by bundling",
						desc: "Start a daily supplement routine and pay less with our curated bundles.",
						link: "Explore Bundles",
						href: "/products?f%5B0%5D=Feature_Bundles&sortp=name.keyword&ascp=ASC",
						background: "/images/pages/search/marketing-product-tiles/bundles.png",
					}
				} else if (!getters.isSearch && !getters.isFiltered && state.currentPage.pageNumber == 1) {
					return genericTest;
				} else if (state.marketingTest) {
					return {
						iconClass: "fal fa-vial",
						title: state.marketingTest.name,
						desc: state.marketingTest.headline,
						link: "Shop now",
						href: state.marketingTest.url,
						background: "/images/pages/search/marketing-product-tiles/specific-test.png",
					}
				} else {
					return genericTest;
				}
			}
			return null;
		},
		
		canShowFacet(state) {
			return (facet) => {
				return facet.populated === true && facet.show === true;
			}
    	},
    	
    	isFilterSelected(state) {
    		return ({ facetName, facetValue }) => {
    			let selected = false;
        		let facet = state.currentPage.facets.find(facet => facet.name === facetName);
    			
        		if(facet) {
        			const valueIndex = facet.selected.findIndex(value => value === facetValue);
        			selected = valueIndex > -1;
        		}
        		
        		return selected;
    		}
    	},
    	
    	isTabSelected(state) {
    		return (tab) => {
    			return state.tab === tab;
    		}
		},
		
		isFacetExpanded(state) {
			return (facet) => {
				return state.expandedFacets.findIndex(f => f === facet.name) > -1;
			}
		},
		
		iconFor(state) {
			return (tab) => {
				return state.icons[tab];
			}
		},
	}
}