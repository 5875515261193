<template>
    <div class="video-play-button">
        <a @click="show" @keypress.enter="show" role="button" tabindex="0" :aria-label="ariaLabel ? ariaLabel : 'Click to open video player modal'">
            <slot></slot>
        </a>
        <div class="video-overlay" v-show="visible">
            <div class="mask" @click="visible=false"></div>
            <div class="modal-body">
                <div class="video-container">
                    <iframe v-if="visible" width="910" height="540" :src="videoUrl" frameborder="0"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'VideoOverlay',

        props: {
            youtubeEmbedCode: { type: String },
            autoplay: { default: true },
            ariaLabel: { type: String },
        },

        data() {
            return {
                visible: false
            }
        },

        methods: {
            hide() {
                this.visible = false;
            },

            show() {
                this.visible=true;
                this.$emit("played", this.youtubeEmbedCode);
            }
        },

        computed: {
            videoUrl() {
                return "https://www.youtube.com/embed/" + this.youtubeEmbedCode + "?rel=0&controls=0&fs=1&showinfo=0&modestbranding=1&autoplay=" + (this.autoplay ? "1" : "0");
            }
        }
    }
</script>
