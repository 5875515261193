<template>
  <div class="marketing-review">
    <div v-if="horizontal" class="marketing-review--horizontal">
      <div class="columns">
        <div class="column is-hidden-tablet">
          <h3 v-if="title" class="title is-3">{{ title }}</h3>
          <p class="review">
            <slot></slot>
          </p>
        </div>
        <div class="left column">
          <div class="stars">
            <review-stars :value="stars"></review-stars>
          </div>
          <div class="author">
            <span>{{ author }}</span>
          </div>
          <div v-if="verified">
            <span class="verified">
              <i class="check fas fa-check-circle"></i>
              <span>Verified</span>
            </span>
          </div>
        </div>
        <div class="right column is-hidden-mobile">
          <h3 v-if="title" class="title is-3">{{ title }}</h3>
          <p class="review">
            <slot></slot>
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <h2 v-if="title" class="title is-2">{{ title }}</h2>
      <p class="review">
        <slot></slot>
      </p>
      <review-stars :value="stars"></review-stars>
      <span class="bull">&bull;</span> {{ author }}
      <span v-if="verified">
        <span class="bull">&bull;</span>
        <span class="verified">
          <i class="check fas fa-check-circle"></i>
          <span> Verified </span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import ReviewStars from "../../components/reviews/ReviewStars.vue";

export default {
  props: {
    title: { type: String, default: null },
    stars: { type: Number, default: 5 },
    verified: { type: Boolean, default: true },
    author: { type: String, required: true },
    horizontal: { type: Boolean, default: false },
  },

  components: {
    "review-stars": ReviewStars,
  },
};
</script>