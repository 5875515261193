<template>
  <div class="product-focus">
    <marketing-headline v-if="hasHeadline" class="block" full>
      <template #title>
        <slot name="headline"></slot>
      </template>
    </marketing-headline>

    <div class="product-focus--flexbox block">
      <div class="product-focus--image block is-128x128">
        <a :href="imgHref ? imgHref : buttonHref">
          <img :role="!imageDescription ? 'presentation' : null" :alt="imageDescription" :src="$cdn(image)" />
        </a>
      </div>

      <div class="product-focus--description block">
        <h1 class="title is-1">
          <slot name="title"></slot>
        </h1>
        <p class="description block">
          <slot></slot>
        </p>
        <a v-if="buttonHref" :href="buttonHref" class="button is-black">
          {{ buttonText }}
        </a>
      </div>
    </div>

    <marketing-product-set
      v-if="hasSkus"
      class="block"
      :skus="skus"
    ></marketing-product-set>
  </div>
</template>

<script>
import MarketingHeadline from "./MarketingHeadline.vue";
import MarketingProductSet from "./MarketingProductSet.vue";

export default {
  name: "ProductFocus",

  components: {
    "marketing-headline": MarketingHeadline,
    "marketing-product-set": MarketingProductSet,
  },

  props: {
    buttonText: { type: String, default: "Shop Now" },
    buttonHref: { type: String, default: null },
    image: { type: String, required: true },
    imgHref: { type: String, default: null },
    imageDescription: { type: String, default: "" },
    skus: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  computed: {
    hasHeadline() {
      return !!this.$slots.headline;
    },

    hasSkus() {
      return this.skus.length !== 0;
    },
  },
};
</script>