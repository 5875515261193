export default {
  methods: {
    iconClass(type) {
      if (type === "article") {
        return "fa fa-newspaper";
      } else if (type === "video") {
        return "fa fa-video";
      } else if (type === "podcast") {
        return "fa fa-podcast";
      } else {
        return "";
      }
    },

    iconColor(type) {
      if (type === "article") {
        return "rgb(127, 224, 167)";
      } else if (type === "video") {
        return "rgb(91, 194, 231)";
      } else if (type === "podcast") {
        return "rgb(228, 93, 191)";
      } else {
        return "#888888";
      }
    },
  },
};
