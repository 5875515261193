<template>
  <div
    class="shop-now columns is-vcentered is-animated is-mobile"
    :style="buttonStyle"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <a v-if="link" :href="link" :aria-label="`Shop products recommended by ${name}`"></a>

    <div v-if="canShop" class="shop column is-one-third">
      <div class="shop-now--text">
        Shop
        <br />Now
      </div>
    </div>

    <div v-else class="logo column is-one-third">
      <div class="shop-now--image" role="presentation" :style="logoStyle"></div>
    </div>

    <div class="org-name column">{{ name }}</div>
  </div>
</template>

<style lang="scss" scoped>

.shop-now.columns {
  position: relative;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    text-decoration: none;
  }

  .shop.column {
    background-color: #ff9e1c;
    transform: skewX(-20deg) translate(-10px);
    transition: inherit;
    height: 100%;

    .shop-now--text {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fcfcfc;
      transform: skewX(20deg) translate(10px);
    }
  }

  .logo.column {
    height: 100%;

    .shop-now--image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 80%;
    }
  }

  .org-name.column {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #191919;
  }
}
</style>

<script>
export default {
  name: "ShopNow",

  props: {
    name: { type: String },
    link: { default: null, type: String },
    logo: { default: null, type: String },
    width: { default: null, type: Number },
  },

  data: function () {
    return {
      hover: false,
    };
  },

  computed: {
    canShop() {
      return this.hover && this.link !== null;
    },

    buttonStyle() {
      let style = {};

      if (this.canShop) {
        style.backgroundColor = "#fcfcfc";
        style.boxShadow = "0 10px 14px 0 rgba(0, 0, 0, 0.08)";
      }

      if (this.width) {
        style.width = this.width;
      }
      return style;
    },

    logoStyle() {
      return {
        backgroundImage: `url(${this.$cdn(this.logo)})`,
      };
    },
  },
};
</script>