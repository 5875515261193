import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import root from "./store/root.js";
import search from "./store/search.js";

const store = new Vuex.Store({

    // FIXME - Using the root module without a namespace doesn't seem to be working ...
    // FIXME - Some of the root state needs to be broken out into their own modules
    state: root.state,
    getters: root.getters,
    mutations: root.mutations,

    modules: {
		search
    },
});

export default store;