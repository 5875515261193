<template>
    <div class="notification-banner" :class="classes">
    	<span class="badge" :class="badgeClasses" v-if="badge && icon"><i :class="icon"></i></span>
    	<span class="icon" v-else-if="icon"><i :class="icon"></i></span>
    	<template v-if="text">{{text}}</template>
    	<slot v-else></slot>
    </div>
</template>

<script>
export default {
	name: "Notification",
	
	props: ["icon", "text", "classes", "badge", "badgeClasses"]
}
</script>