<template>
	<div class="field">
		<label class="label" :id="labelId ? labelId : null" v-if="label" v-html="label"></label>
		<div class="control" :class="{ 'has-icons-right' : hasSomethingOnRight }">
			<slot>
				<!-- Elements will get injected here. -->
			</slot>
			<span class="icon is-right" v-if="hasSomethingOnRight">
				<i :class="rightIcon" v-if="rightIcon"></i>
				<span class="right-content" v-if="rightContent">{{ rightContent }}</span>
			</span>
		</div>
		<div :id="errorId" class="help is-danger" v-if="hasError" :aria-label="`${errorPrefix || label} ${errorMessage}`" role="alert">{{errorMessage}}</div>
	</div>
</template>

<script>
	export default {
		props: {
			label: { type: String },
      errorPrefix: { type: String },
			errorField: { type: String },
			rightIcon: { type: String },
      labelId: { type: String },
			rightContent: {
				type: String,
				default: ""
			},
			errors: {
	            type: Object,
	            default: () => ({})
	        }
		},

		computed: {
      errorId() {
        if (this.labelId) {
          return `error-${this.labelId}`;
        }
        return null;
      },

			hasError() {
				return Object.keys(this.errors).length > 0 && this.errors[this.errorField] != null;
			},
			
			hasSomethingOnRight() {
				return this.rightIcon || this.rightContent;
			},
			
			errorMessage() {
				return this.hasError ? this.errors[this.errorField] : null;
			}
		}
	}
</script>