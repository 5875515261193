// Make components globally accessible for content pages
import VideoOverlay from "../../components/VideoOverlay.vue";
import AffiliateVideoCard from "../../components/AffiliateVideoCard.vue";
import YouTube from "../../components/YouTube.vue";
import ProductSet from "../../components/ProductSet.vue";
import NewsletterSubscription from "../../components/NewsletterSubscription.vue";
import SocialNetworkSharing from "../../components/SocialNetworkSharing.vue";
import AjaxAddToCartButton from "../../components/AjaxAddToCartButton.vue";
import ShopNow from "../../components/ShopNow.vue";
import Quiz from "../../components/quizzes/QuizQuestions.vue";
import QuizCard from "../../components/features/QuizCard.vue";
import AuraHeader from "../../components/features/AuraHeader.vue";
import ImageCard from "../../components/features/ImageCard.vue";
import { Carousel, Slide } from "vue-carousel";
import { CollapseTransition } from "vue2-transitions";
import VueScrollTo from "vue-scrollto";
import ProductTile from "../../components/ProductTile.vue";
import ReviewStars from "../../components/reviews/ReviewStars.vue";
import ImageCardNew from "../../components/features/ImageCardNew.vue";
import MarketingBanner from "../../components/marketing/MarketingBanner.vue";
import MarketingHeadline from "../../components/marketing/MarketingHeadline.vue";
import TriPanelVideo from "../../components/marketing/TriPanelVideo.vue";
import MarketingReview from "../../components/marketing/MarketingReview.vue";
import MarketingCard from "../../components/marketing/MarketingCard.vue";
import ProductFocus from "../../components/marketing/ProductFocus.vue";
import MarketingProductSet from "../../components/marketing/MarketingProductSet.vue";
import VerticalBlogStream from "../../components/marketing/VerticalBlogStream.vue";
import HorizontalMarketingCard from "../../components/marketing/HorizontalMarketingCard.vue";
import ProductLinkNav from "../../components/marketing/ProductLinkNav.vue";
import RightSidebarLayout from "../../components/layouts/RightSidebarLayout.vue";
import ImageTile from "../../components/global/ImageTile.vue";
import RewardTiersTable from "../../components/RewardTiersTable.vue";
import RightSidebarBlogStream from "../../components/blog/RightSidebarBlogStream.vue";
import BlogCarousel from "../../components/blog/BlogCarousel.vue";
import AjaxPagination from "../../components/AjaxPagination.vue";
import LoginModal from "../../components/LoginModal.vue";
import Fieldify from "../../components/Fieldify.vue";
import ContentBlock from "../../components/global/ContentBlock.vue";
import SimpleProductTile from "../../components/marketing/SimpleProductTile.vue";

Vue.component("video-overlay", VideoOverlay);
Vue.component("affiliate-video-card", AffiliateVideoCard);
Vue.component("you-tube", YouTube);
Vue.component("carousel", Carousel);
Vue.component("slide", Slide);
Vue.component("collapse-transition", CollapseTransition);
Vue.component("product-set", ProductSet);
Vue.component("newsletter-subscription", NewsletterSubscription);
Vue.component("social-network-sharing", SocialNetworkSharing);
Vue.component("ajax-add-to-cart-button", AjaxAddToCartButton);
Vue.component("quiz", Quiz);
Vue.component("quiz-card", QuizCard);
Vue.component("aura-header", AuraHeader);
Vue.component("image-card", ImageCard);
Vue.component("product-tile", ProductTile);
Vue.component("review-stars", ReviewStars);
Vue.component("image-card-new", ImageCardNew);
Vue.component("shop-now", ShopNow);
Vue.component("marketing-banner", MarketingBanner);
Vue.component("marketing-headline", MarketingHeadline);
Vue.component("tri-panel-video", TriPanelVideo);
Vue.component("marketing-review", MarketingReview);
Vue.component("marketing-card", MarketingCard);
Vue.component("product-focus", ProductFocus);
Vue.component("marketing-product-set", MarketingProductSet);
Vue.component("vertical-blog-stream", VerticalBlogStream);
Vue.component("horizontal-marketing-card", HorizontalMarketingCard);
Vue.component("product-link-nav", ProductLinkNav);
Vue.component("right-sidebar-layout", RightSidebarLayout);
Vue.component("image-tile", ImageTile);
Vue.component("reward-tiers-table", RewardTiersTable);
Vue.component("right-sidebar-blog-stream", RightSidebarBlogStream);
Vue.component("blog-carousel", BlogCarousel);
Vue.component("ajax-pagination", AjaxPagination);
Vue.component("login-modal", LoginModal);
Vue.component("fieldify", Fieldify);
Vue.component("content-block", ContentBlock);
Vue.component("simple-product-tile", SimpleProductTile);

Vue.use(VueScrollTo);
