<template>
    <div class="youtube-video">
        <iframe aria-label="Embedded YouTube video player" :width="width" :height="height" :src="url" frameborder="0" allowfullscreen></iframe>
    </div>
</template>

<script>
	export default {
		name: 'YouTube',
		
	    props: {
	        videoId: { type: String },
	        width: { type: Number, default: 420 },
	        height: { type: Number, default: 315 }
	    },
	    
	    computed: {
	    	url() {
	            return "https://www.youtube.com/embed/" + this.videoId;
	        }
	    }
	}
</script>