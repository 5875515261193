<template>
  <div class="tri-panel-video">
    <div class="content-wrapper">
      <div class="content-inner" :class="{ square: square }">
        <div
          class="top-image is-hidden-touch"
          :class="{ 'quarter-shift-up': showPoster || square }"
          :style="topImgStyle"
        ></div>
        <video
          v-if="video"
          class="video"
          :class="{ 'square-vid': square }"
          autoplay
          muted
          playsinline
          loop
        >
          <source :src="videoSrc" />
        </video>
        <div
          v-if="showPoster"
          class="video image poster"
          :class="{ 'square-vid': square }"
        >
          <img role="presentation" alt="" :src="$cdn(poster)" />
        </div>
        <div
          class="bottom-image is-hidden-touch"
          :class="{ 'quarter-shift-down': showPoster || square }"
          :style="bottomImgStyle"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import YouTube from "../YouTube.vue";
export default {
  name: "TriPanelVideo",

  components: {
    youtube: YouTube,
  },

  props: {
    video: { type: String, default: null },
    poster: { type: String, default: null },
    topImg: { type: String, required: true },
    bottomImg: { type: String, required: true },
    youtube: { type: Boolean, default: false },
    square: { type: Boolean, default: false },
  },

  computed: {
    videoSrc() {
      return this.$cdn(this.video);
    },

    topImgSrc() {
      return this.$cdn(this.topImg);
    },

    bottomImgSrc() {
      return this.$cdn(this.bottomImg);
    },

    topImgStyle() {
      return {
        backgroundImage: `url("${this.topImgSrc}")`,
      };
    },

    bottomImgStyle() {
      return {
        backgroundImage: `url("${this.bottomImgSrc}")`,
      };
    },

    showPoster() {
      return !this.video && this.poster;
    },
  },
};
</script>