var validationMixin = window.vuelidate.validationMixin;
import get from "lodash.get";

export default {
  mixins: [validationMixin],

  data() {
    return {
      messages: {
        generic: () => "Invalid field",
        required: () => "Required",
        requiredIf: (params) => "Required",
        maxFileSize: () => `File is too large`,
        isTrue: () => "Must be checked",
        minLength: (params) => `Must be at least ${params.min} characters`,
        maxLength: (params) => `Must be less than ${params.max} characters`,
        email: () => "Invalid email address",
        minValue: (params) => `Must be at least ${params.min}`,
        maxValue: (params) => `Must be less than ${params.max}`,
        sameAs: (params) => `These fields must match`,
        between: (params) =>
          `Must be between ${
            params.min instanceof Date
              ? params.min.toLocaleDateString()
              : params.min
          } and ${
            params.max instanceof Date
              ? params.max.toLocaleDateString()
              : params.max
          }`,
        momentRange: (params) =>
          `Must be between ${params.min} and ${params.max}`,
        isUS: () => "Only available in the US",
        nameCharacters: (params) => "Names cannot contain special characters. <>?:;\"[]{}|~`!@#$%^*=+'",
        consentRequired: () => "Must be checked"
      },
    };
  },

  methods: {
    addWatcher(key) {
      const field = get(this.$v, key);

      // We need to watch both model and dirty for model changes or programmatic validation (w/o model change)
      this.$watch(
        () => (field.$model, field.$dirty, Date.now()),
        (val) => {
          const isDirty = field.$dirty;
          const isInvalid = field.$invalid;

          if (isDirty && isInvalid) {
            let message = null;

            // Find the first validation failure and set that
            Object.keys(field).forEach((prop) => {
              if (
                message === null &&
                !prop.startsWith("$") &&
                field[prop] === false
              ) {
                const params = field.$params && field.$params[prop];
                if (this.messages[prop]) {
                  message = params ? this.messages[prop](params) : this.messages[prop]();
                }
                else {
                  message = this.messages.generic();
                }
              }
            });

            this.$set(this.errors, key, message);
          } else {
            this.$delete(this.errors, key);
          }
        }
      );
    },
  },

  created() {
    // Dynamically bind watchers that will set and remove error messages on the errors object
    Object.keys(this.$v).forEach((key) => {
      if (!key.startsWith("$")) {
        const field = this.$v[key];

        if (typeof field["$model"] === "object" && field["$model"] !== null) {
          // This is a nested validation (e.g., addressModel.city), build on the key
          Object.keys(field).forEach((childKey) => {
            if (!childKey.startsWith("$")) {
              this.addWatcher(key + "." + childKey);
            }
          });
        } else {
          // Top level validation, just add the watcher
          this.addWatcher(key);
        }
      }
    });
  },
};
